import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import SocialListItem from '../../shared/SocialLinks/SocialListItem'

import * as S from './styled'

const SocialItens = ({ iconsSize }) => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeSettings {
        instagram_url
        linkedin_url
        youtube_url
        facebook_url
      }
    }
  `)

  const {
    wordpressGeneoneThemeSettings: {
      instagram_url: instagramUrl,
      linkedin_url: linkedinUrl,
      youtube_url: youtubeUrl,
      facebook_url: facebookUrl,
    },
  } = data

  return (
    <S.SocialLinksHolder>
      <S.SocialLinksListHolder>
        <SocialListItem size={iconsSize} link={linkedinUrl} type="linkedin" />
        <SocialListItem size={iconsSize} link={facebookUrl} type="facebook" />
        <SocialListItem size={iconsSize} link={youtubeUrl} type="youtube" />
        <SocialListItem size={iconsSize} link={instagramUrl} type="instagram" />
      </S.SocialLinksListHolder>
    </S.SocialLinksHolder>
  )
}

SocialItens.propTypes = {
  iconsSize: PropTypes.string,
}

SocialItens.defaultProps = {
  iconsSize: '19',
}

export default SocialItens
