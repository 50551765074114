import React, { useEffect, useState } from 'react'
import ContactInfo from './ContactInfo'
import { ContactUsDiv, SubtitleItem } from './styled'
import { FooterTitle, Title } from '../styled'

const GroupNamContact = ({data}) => {
  const widgets = data.widgets
  const [widgetsText, setWidgetsText] = useState([])

  useEffect(() => {
    const findTitle = () => {
      const result = widgets.find(function(side) {
        return side.type === "text"
      })
  
      if(!result) return data.name
  
      setWidgetsText(widgets.filter(widget => widget.type === "text"))
  
      return result.value.rendered
    }
    
    findTitle()
  }, [data, widgets])

  const title = widgetsText && widgetsText[0]?.value ? widgetsText[0].value.rendered : data.name
  const description = widgetsText && widgetsText[1]?.value ? widgetsText[1].value.rendered : false

  return (
    <ContactUsDiv className="nam" key={Math.random()}>
      <FooterTitle className="contact-footer">
        <Title dangerouslySetInnerHTML={{ __html: title }} />

          {description && (
            <SubtitleItem 
              dangerouslySetInnerHTML={{ __html: description }} 
            />
          )}
      </FooterTitle>

      {widgets.map(widget => {
        if (widget.type !== 'footer_information') return null

        const widgetValues = JSON.parse(widget.value.rendered)

        return (
          <ContactInfo
            key={`nam_${widgetValues.title}`}
            icon={widgetValues.icon}
            title={widgetValues.text}
            text={null}
            subtext={widgetValues.subtext}
          />
        )
      })}
    </ContactUsDiv>
  )
}

export default GroupNamContact
