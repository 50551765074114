import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isHome } from '@cms/utils'

import * as S from './styled'

const Breadcrumb = ({ location, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const [crumbsData, setCrumbsData] = useState(crumbs)

  const generateCrumbLabel = ({ pathname }) => {
    const splitedPathname = pathname.split('/')
    const lastPartPathName = splitedPathname[splitedPathname.length - 1]
    return lastPartPathName.toLowerCase().split('-').join(' ')
  }

  const breadcrumbs = crumbs => {
    const breadHome = [{
      pathname: '/', crumbLabel: 'Home'
    }]

    const breadcrumbs = crumbs.map(crumb => {
      const pathname = `/${crumb}`
      return {
          pathname: pathname,
          crumbLabel: generateCrumbLabel({ pathname: pathname })
      }
    })

    return breadHome.concat(breadcrumbs)
  }

  if(crumbsData.length <= 1 && location.pathname !== '/' && location.pathname !== `/${process.env.GATSBY_COUNTRY.toLowerCase()}/`) {
    const paths = location.pathname.split('/').filter(item => item !== '' && item !== `${process.env.GATSBY_COUNTRY.toLowerCase()}`)
    setCrumbsData(breadcrumbs(paths))
  }

  return (
    !isHome(location) && (
      <S.BreadcrumbHolder>
        {crumbsData.length > 0 && (
          <S.Breadcrumb crumbs={crumbsData} crumbLabel={generateCrumbLabel(location)} />
        )}
      </S.BreadcrumbHolder>
    )
  )
}

Breadcrumb.propTypes = {
  location: PropTypes.object.isRequired,
  crumbLabel: PropTypes.string,
  pageContext: PropTypes.object,
  hasHeader: PropTypes.bool,
}

export default Breadcrumb
