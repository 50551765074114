import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import ModalNavContext from '../../../utils/contexts/ModalNavContext'
import modalNav from '../../BannerNav/modalNav'

const CommonMenu = ({ menu, addClass }) => {
  const {timerModalNav, setTimerModalNav} = useContext(ModalNavContext)

  function cancelTimerModalNav() {
    if(!timerModalNav) return 

    clearTimeout(timerModalNav)
    setTimerModalNav(false)
  }

  function openModalNavByHeader(e, modal) {
    cancelTimerModalNav()

    if(timerModalNav) setTimerModalNav(false)

    modalNav(e, modal);
  }

  return (
    <>
      {(menu.url.match(/^https?:\/\//g)
      ?
        <S.MenuItemDasaNavDesktopCommomExternal
          data-testid="simple-menu"
          href={menu.url}
          target='_blank'
          className={addClass ? addClass : ''}
        >
          {menu.title}
        </S.MenuItemDasaNavDesktopCommomExternal>
      :
        <S.MenuItemDasaNavDesktopCommom
          data-testid="simple-menu" 
          to={menu.url}
          target='_self'
          className={addClass ? addClass : ''}
          onClick={menu.url === '#' ? e => openModalNavByHeader(e, menu.slug) : (e) => {}}
        >
          {menu.title}
        </S.MenuItemDasaNavDesktopCommom>
      )}
    </>
  )
}  

CommonMenu.propTypes = {
  menu: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
}

export default CommonMenu
