import React from 'react'
import CommonMenu from './commomMenu'
import DropdownMenu from './dropdownMenu'

const SubMenuItem = props =>
  props.menu.submenus && props.menu.submenus.length > 0 ? (
    <DropdownMenu menu={props.menu} />
  ) : (
    <CommonMenu 
      menu={props.menu}
      addClass={props.url?.location?.pathname === props.menu.url ? 'active' : ''}
    />
  )

SubMenuItem.propTypes = {
  menu: DropdownMenu.propTypes.menu
}

export default SubMenuItem
