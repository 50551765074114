import React from 'react'

const ImgIllustration = () => {
    return (
        <svg viewBox="0 0 469 299" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M350.731 85.6367C332.741 100.639 343.085 116.131 336.088 125.481C324.957 140.383 314.971 134.569 315.322 163.95L337.361 179.709L375.778 160.86L368.958 123.24L350.731 85.6367Z" fill="#000E40"/>
            <path d="M390.548 85.6367C408.539 100.639 398.194 116.131 405.189 125.481C416.323 140.383 425.538 136.804 424.102 167.316L403.918 179.709L365.502 160.86L372.316 123.24L390.548 85.6367Z" fill="#000E40"/>
            <path d="M-3.8223 162.684C-20.9301 162.609 -34.946 147.584 -35.1747 129.074C-35.2152 125.913 -34.8493 122.762 -34.0873 119.71L-52.8089 133.942L-71.5358 119.707C-69.5086 127.841 -70.3344 136.5 -73.8543 144.014C-77.3742 151.529 -83.3391 157.369 -90.6003 160.408L-90.6641 160.725L-90.603 160.406L-109.325 166.827C-114.42 168.574 -119.028 171.679 -122.726 175.856C-126.424 180.032 -129.094 185.147 -130.489 190.73L-148.512 276.258L-112.451 273.353L-113.27 277.579V327.702C-113.27 334.556 -112.022 341.342 -109.598 347.674C-107.175 354.006 -103.622 359.76 -99.143 364.606C-94.6642 369.452 -89.3472 373.296 -83.4955 375.919C-77.6438 378.541 -71.372 379.891 -65.0383 379.891H7.64406V160.408C3.9864 161.934 0.0974731 162.705 -3.8223 162.684Z" fill="#A6E1FF"/>
            <path d="M7.64406 273.14V160.408C0.348572 163.442 -7.72731 163.442 -15.0228 160.408L-14.9617 160.728L-15.0228 160.411L-52.8116 203.247C-52.8116 203.247 -90.6508 160.728 -90.6003 160.411L-90.6641 160.728L-90.603 160.408L-109.325 166.829C-119.677 170.379 -127.972 177.241 -130.489 190.733L-148.512 276.258L-112.451 273.353L-113.27 277.579V327.702C-113.27 334.555 -112.022 341.342 -109.598 347.674C-107.175 354.006 -103.622 359.759 -99.143 364.606C-94.6642 369.452 -89.3472 373.296 -83.4955 375.918C-77.6438 378.541 -71.372 379.891 -65.0383 379.89H7.64406V277.579L6.82524 273.353L7.64406 273.14Z" fill="#FF4E35"/>
            <path d="M51.9543 163.479C73.237 163.479 90.4901 144.81 90.4901 121.78C90.4901 98.7496 73.237 80.0801 51.9543 80.0801C30.6715 80.0801 13.4185 98.7496 13.4185 121.78C13.4185 144.81 30.6715 163.479 51.9543 163.479Z" fill="#A6E1FF"/>
            <path d="M104.559 147.891C99.3801 147.887 94.2712 146.593 89.6284 144.11C84.9855 141.626 80.9333 138.019 77.7856 133.569C74.638 129.118 72.4793 123.943 71.4769 118.445C70.4745 112.946 70.6553 107.272 72.0052 101.861L51.9543 117.109L31.9088 101.864C34.0802 110.576 33.1958 119.849 29.4261 127.897C25.6563 135.945 19.2677 142.199 11.491 145.454L11.4272 145.793L11.491 145.451L-8.5572 152.327C-14.0145 154.199 -18.9499 157.525 -22.9104 161.998C-26.8708 166.472 -29.7295 171.95 -31.2241 177.93L-50.5332 269.52L-11.9123 266.407L-12.7896 270.933V379.89H116.696V145.454C112.824 147.07 108.709 147.896 104.559 147.891Z" fill="#FF4E35"/>
            <path d="M116.696 266.181V145.455C108.883 148.704 100.235 148.704 92.4229 145.455L92.4893 145.797L92.4229 145.458L51.9543 191.332C51.9543 191.332 11.4192 145.797 11.483 145.458L11.4192 145.797L11.483 145.455L-8.56517 152.331C-19.654 156.134 -28.5336 163.481 -31.2321 177.934L-50.5332 269.521L-11.9123 266.409L-12.7896 270.934V379.891H116.696V270.934L115.821 266.409L116.696 266.181Z" fill="#000E40"/>
            <path d="M-1.80176 273.234L1.17317 251.586C48.4051 265.838 71.7446 236.79 134.747 271.197V287.794C134.747 303.792 120.375 315.173 106.282 310.337L-1.80176 273.234Z" fill="#CEB189"/>
            <path d="M20.7428 258.698C20.1074 258.606 0.537781 253.174 -8.4322 242.774C-17.7531 231.978 -26.8853 228.39 -34.3638 226.857C-34.9971 226.728 -35.652 226.849 -36.2106 227.196C-36.7692 227.544 -37.1947 228.096 -37.4105 228.753C-37.5406 229.147 -37.591 229.566 -37.5583 229.983C-37.5257 230.4 -37.4107 230.804 -37.2211 231.169C-37.0316 231.534 -36.772 231.85 -36.4597 232.097C-36.1475 232.343 -35.7899 232.515 -35.4113 232.599C-31.4686 233.477 -24.2666 236.152 -16.6179 244.334L-27.6297 237.769C-29.423 236.698 -31.5304 236.423 -33.5077 237.001L-45.9338 240.81C-46.5773 241.007 -47.1292 241.457 -47.481 242.073C-47.8328 242.688 -47.959 243.424 -47.8347 244.135C-47.7058 244.88 -47.3123 245.54 -46.7387 245.974C-46.1651 246.408 -45.4571 246.581 -44.7667 246.457L-33.8666 244.509C-32.1982 244.239 -30.4936 244.552 -29.0015 245.404L-19.9624 250.648C-19.9624 250.648 -28.9616 245.384 -44.5727 249.785C-45.2062 249.963 -45.7672 250.364 -46.1675 250.924C-46.5679 251.484 -46.7849 252.172 -46.7846 252.881C-46.7852 253.359 -46.6861 253.832 -46.4948 254.263C-46.3035 254.694 -46.0248 255.073 -45.6794 255.372C-45.3341 255.671 -44.931 255.882 -44.5 255.989C-44.0691 256.096 -43.6214 256.096 -43.1902 255.991C-38.8328 254.912 -30.8784 253.919 -21.39 257.245L-29.3099 256.534C-32.1165 256.285 -34.9412 256.446 -37.7083 257.012L-41.1059 257.705C-41.6691 257.82 -42.1793 258.139 -42.5524 258.61C-42.9255 259.08 -43.1392 259.675 -43.1583 260.294C-43.1696 260.674 -43.1077 261.052 -42.9765 261.404C-42.8453 261.756 -42.6476 262.074 -42.3962 262.339C-42.1448 262.604 -41.8451 262.809 -41.5162 262.941C-41.1874 263.074 -40.8367 263.13 -40.4865 263.108C-36.3949 262.849 -28.5921 263.088 -22.8044 267.679C-15.1716 273.72 -4.69426 277.27 10.2309 277.27L20.7428 258.698Z" fill="#CEB189"/>
            <path d="M18.1055 141.729C21.4208 148.311 26.3139 153.805 32.2702 157.632C38.2264 161.459 45.0257 163.479 51.9529 163.479C58.8802 163.479 65.6794 161.459 71.6357 157.632C77.5919 153.805 82.485 148.311 85.8004 141.729C79.941 137.478 75.4595 131.347 72.9853 124.198C70.511 117.05 70.1682 109.242 72.0051 101.871L51.9543 117.118L31.9087 101.865C33.7462 109.236 33.4038 117.045 30.9294 124.194C28.4551 131.344 23.9733 137.475 18.1134 141.727L18.1055 141.729Z" fill="#B39A78"/>
            <path d="M82.9745 277.546L79.9995 255.895C32.7676 270.147 12.4721 235.113 -50.5357 269.52L-52.4871 277.543C-56.5175 294.099 -42.8153 309.347 -27.4089 305.448L82.9745 277.546Z" fill="#B39A78"/>
            <path d="M84.7901 74.4923C84.6253 72.6511 83.1285 71.1926 81.4536 70.8129C80.5031 70.6311 79.5242 70.7551 78.6382 71.1696C78.3139 71.3048 78.3086 71.3019 78.6382 71.1696C78.9041 68.2093 79.029 65.0247 79.0078 61.5782C78.8988 45.2666 67.0602 32.1367 51.9862 32.2547L51.9197 32.6028V32.2547C36.8457 32.1367 25.0071 45.2666 24.9008 61.5782C24.8723 64.7799 24.993 67.9811 25.2623 71.1696C25.5893 71.3019 25.5867 71.3048 25.2623 71.1696C24.3749 70.757 23.3951 70.6349 22.4443 70.8186C20.7694 71.1983 19.2726 72.6569 19.1078 74.4981C18.8951 76.86 22.9175 85.4559 22.9175 85.4559C23.9809 87.976 28.5377 88.724 28.5377 88.724C31.587 113.177 45.8768 117.113 51.933 117.113C57.9892 117.113 72.3188 113.171 75.3602 88.7183C75.3602 88.7183 79.9064 87.9703 80.9804 85.4502C80.9804 85.4502 85.0055 76.8542 84.7901 74.4923Z" fill="#CEB189"/>
            <path d="M25.2627 71.169C32.8369 56.5662 51.9467 61.5288 51.9467 44.8604L41.775 36.7305L25.2627 56.8366V71.169Z" fill="#A6E1FF"/>
            <path d="M51.9546 44.8534V25.5239L51.92 25.6965V25.3484C36.846 25.2305 24.9968 38.3719 24.9011 54.6749C24.8772 58.1271 25.0154 68.196 25.2706 71.162L27.1609 64.597C28.8076 58.8751 32.1018 53.8728 36.5624 50.3207C41.023 46.7686 46.4163 44.8529 51.9546 44.8534Z" fill="#000E40"/>
            <path d="M78.6386 71.169C71.0643 56.5662 51.9546 61.5288 51.9546 44.8604L62.1236 36.7305L78.6386 56.8424V71.169Z" fill="#A6E1FF"/>
            <path d="M51.9546 44.8534V25.5239L51.9865 25.6965V25.3484C67.0605 25.2305 78.8991 38.3603 79.0081 54.6749C79.0293 58.1271 78.8911 68.196 78.6385 71.162L76.7483 64.597C75.1009 58.8756 71.8065 53.8738 67.346 50.3218C62.8856 46.7699 57.4927 44.8538 51.9546 44.8534Z" fill="#000E40"/>
            <path d="M55.8494 94.6612C55.3916 94.4047 54.8727 94.3043 54.3612 94.3731C53.8496 94.4419 53.3693 94.6767 52.9835 95.0467L52.0184 95.9759L51.0507 95.0467C50.6653 94.6759 50.1851 94.4403 49.6734 94.371C49.1618 94.3017 48.6427 94.4018 48.1847 94.6583C46.4857 95.5623 44.6117 96.014 42.7188 95.9759C42.7187 95.9759 45.8186 102.642 52.0237 102.642C58.2235 102.642 61.3287 95.9759 61.3287 95.9759C59.4315 96.0167 57.5528 95.5659 55.8494 94.6612Z" fill="#000E40"/>
            <path d="M321.822 162.683C338.927 162.608 352.946 147.583 353.172 129.073C353.213 125.912 352.848 122.761 352.087 119.709L370.812 133.941L389.536 119.709C387.509 127.843 388.334 136.502 391.854 144.016C395.374 151.531 401.339 157.371 408.6 160.41L408.661 160.727L408.6 160.408L427.322 166.829C432.418 168.575 437.027 171.679 440.725 175.856C444.423 180.033 447.092 185.148 448.487 190.732L466.512 276.258L430.448 273.352L431.267 277.578V327.701C431.267 334.555 430.02 341.341 427.596 347.673C425.172 354.005 421.619 359.759 417.14 364.605C412.662 369.451 407.345 373.295 401.493 375.918C395.641 378.54 389.369 379.89 383.036 379.89H310.356V160.408C314.014 161.933 317.903 162.704 321.822 162.683Z" fill="#A6E1FF"/>
            <path d="M310.356 273.14V160.408C317.651 163.442 325.726 163.442 333.02 160.408L332.959 160.728L333.02 160.411L370.809 203.247C370.809 203.247 408.659 160.728 408.598 160.411L408.659 160.728L408.598 160.408L427.319 166.829C437.674 170.379 445.966 177.241 448.484 190.733L466.509 276.258L430.446 273.353L431.265 277.579V327.702C431.265 334.555 430.017 341.342 427.593 347.674C425.169 354.006 421.616 359.759 417.138 364.606C412.659 369.452 407.342 373.296 401.49 375.918C395.639 378.541 389.367 379.891 383.033 379.89H310.356V277.579L311.175 273.353L310.356 273.14Z" fill="#FF4E35"/>
            <path d="M399.957 266.153C400.55 266.066 418.814 260.997 427.197 251.285C435.901 241.216 444.429 237.865 451.411 236.42C452.002 236.3 452.614 236.412 453.136 236.737C453.658 237.062 454.055 237.578 454.255 238.193C454.377 238.56 454.424 238.952 454.394 239.341C454.364 239.73 454.257 240.107 454.081 240.448C453.905 240.788 453.663 241.084 453.372 241.315C453.081 241.546 452.748 241.706 452.394 241.786C448.71 242.603 441.989 245.1 434.848 252.741L445.134 246.61C446.809 245.612 448.775 245.355 450.621 245.894L462.223 249.45C462.824 249.634 463.339 250.055 463.667 250.629C463.996 251.203 464.114 251.89 463.999 252.554C463.879 253.249 463.511 253.866 462.975 254.271C462.439 254.677 461.778 254.839 461.133 254.723L450.959 252.905C449.402 252.652 447.811 252.945 446.418 253.739L437.969 258.63C437.969 258.63 446.37 253.716 460.947 257.83C461.537 257.995 462.06 258.367 462.434 258.887C462.808 259.408 463.013 260.047 463.015 260.707C463.016 261.153 462.924 261.595 462.745 261.997C462.567 262.4 462.306 262.754 461.984 263.033C461.662 263.312 461.286 263.509 460.883 263.61C460.481 263.71 460.063 263.711 459.66 263.612C455.593 262.603 448.165 261.676 439.296 264.783L446.692 264.119C449.316 263.882 451.958 264.03 454.545 264.559L457.717 265.206C458.244 265.312 458.721 265.61 459.07 266.05C459.418 266.49 459.617 267.046 459.634 267.625C459.644 267.98 459.586 268.333 459.464 268.662C459.341 268.991 459.156 269.288 458.921 269.535C458.687 269.782 458.407 269.974 458.099 270.097C457.792 270.221 457.464 270.273 457.137 270.252C453.317 270.013 446.03 270.235 440.625 274.524C433.5 280.177 423.717 283.48 409.786 283.48L399.957 266.153Z" fill="#B39A78"/>
            <path d="M402.417 156.927C399.324 163.075 394.755 168.207 389.193 171.782C383.631 175.357 377.281 177.243 370.812 177.24C359.81 177.24 349.764 172.113 343.163 163.696C341.533 161.559 339.656 159.657 337.58 158.037C346.005 151.924 353.183 140.414 353.183 128.613C353.185 125.606 352.82 122.612 352.095 119.709L370.82 133.941L389.544 119.709C387.829 126.591 388.149 133.882 390.459 140.557C392.769 147.232 396.954 152.957 402.424 156.927H402.417Z" fill="#B39A78"/>
            <path d="M341.844 283.767L344.622 263.551C388.727 276.859 407.68 244.144 466.512 276.273L468.335 283.767C472.097 299.227 459.296 313.461 444.916 309.825L341.844 283.767Z" fill="#CEB189"/>
            <path d="M340.148 94.1422C340.305 92.4161 341.7 91.0611 343.264 90.7073C344.152 90.5346 345.068 90.6497 345.896 91.0381C346.199 91.1618 346.201 91.159 345.896 91.0381C345.659 88.2706 345.744 85.3132 345.765 82.0969C345.866 66.8497 356.705 54.5887 370.782 54.698L370.841 55.0231V54.698C384.915 54.5887 395.97 66.8468 396.071 82.0797C396.094 85.0702 395.979 88.0601 395.725 91.0381C395.419 91.159 395.425 91.1618 395.725 91.0381C396.553 90.6497 397.469 90.5346 398.357 90.7073C399.92 91.0611 401.316 92.4334 401.473 94.1422C401.672 96.3487 397.913 104.378 397.913 104.378C396.913 106.728 392.676 107.416 392.676 107.416C389.831 130.252 376.488 136.791 370.82 136.791C365.152 136.791 351.808 130.252 348.964 107.416C348.964 107.416 344.71 106.717 343.716 104.366C343.708 104.378 339.948 96.3487 340.148 94.1422Z" fill="#CEB189"/>
            <path d="M214.656 147.891C219.835 147.887 224.944 146.594 229.587 144.111C234.23 141.627 238.283 138.02 241.431 133.57C244.579 129.119 246.738 123.944 247.74 118.445C248.743 112.947 248.562 107.272 247.212 101.861L267.263 117.109L287.314 101.861C285.143 110.573 286.027 119.846 289.797 127.894C293.566 135.942 299.955 142.196 307.732 145.451L307.798 145.791L307.732 145.448L327.783 152.324C333.24 154.196 338.175 157.521 342.135 161.995C346.095 166.469 348.953 171.947 350.447 177.928L369.751 269.52L331.13 266.407L332.007 270.933V379.89H202.535V145.454C206.402 147.068 210.511 147.894 214.656 147.891Z" fill="#FF4E35"/>
            <path d="M233.428 141.73C236.743 148.311 241.636 153.805 247.592 157.632C253.549 161.46 260.348 163.479 267.275 163.479C274.202 163.479 281.002 161.46 286.958 157.632C292.914 153.805 297.807 148.311 301.123 141.73C295.263 137.479 290.781 131.348 288.306 124.199C285.831 117.05 285.488 109.242 287.325 101.871L267.274 117.118L247.223 101.871C249.06 109.242 248.717 117.05 246.243 124.199C243.769 131.347 239.287 137.478 233.428 141.73Z" fill="#B39A78"/>
            <path d="M202.522 266.181V145.455C210.333 148.704 218.981 148.704 226.792 145.455L226.726 145.797L226.792 145.458L267.261 191.332C267.261 191.332 307.796 145.797 307.729 145.458L307.796 145.797L307.729 145.455L327.78 152.331C333.237 154.203 338.172 157.528 342.132 162.002C346.092 166.476 348.951 171.954 350.444 177.934L369.748 269.527L331.13 266.409L332.007 270.934V379.891H202.535V270.934L203.41 266.409L202.522 266.181Z" fill="#000E40"/>
            <path d="M321.019 273.234L318.044 251.586C270.812 265.838 247.473 236.79 184.47 271.197V287.794C184.47 303.792 198.842 315.173 212.935 310.337L321.019 273.234Z" fill="#CEB189"/>
            <path d="M298.475 258.698C299.11 258.606 318.68 253.174 327.65 242.774C336.971 231.978 346.103 228.39 353.579 226.857C354.212 226.728 354.867 226.849 355.425 227.196C355.984 227.544 356.41 228.096 356.625 228.753C356.756 229.146 356.807 229.566 356.774 229.983C356.742 230.4 356.627 230.804 356.438 231.169C356.249 231.534 355.989 231.85 355.677 232.097C355.365 232.344 355.007 232.515 354.629 232.599C350.683 233.477 343.484 236.152 335.833 244.334L346.847 237.769C348.64 236.698 350.748 236.423 352.725 237.001L365.149 240.81C365.792 241.007 366.344 241.457 366.697 242.073C367.049 242.688 367.176 243.424 367.052 244.135C366.923 244.88 366.529 245.54 365.955 245.974C365.381 246.408 364.672 246.581 363.981 246.457L353.081 244.509C351.413 244.239 349.709 244.553 348.216 245.404L339.177 250.648C339.177 250.648 348.174 245.384 363.785 249.785C364.418 249.962 364.979 250.361 365.38 250.92C365.781 251.48 365.999 252.167 365.999 252.875C366 253.353 365.901 253.825 365.71 254.256C365.518 254.687 365.24 255.066 364.895 255.365C364.55 255.664 364.147 255.875 363.717 255.982C363.286 256.089 362.839 256.09 362.408 255.985C358.05 254.906 350.096 253.913 340.607 257.239L348.525 256.529C351.335 256.276 354.165 256.433 356.936 256.997L360.334 257.691C360.897 257.806 361.407 258.125 361.78 258.595C362.154 259.066 362.367 259.66 362.386 260.28C362.397 260.658 362.335 261.034 362.205 261.385C362.074 261.736 361.878 262.054 361.628 262.318C361.378 262.583 361.08 262.788 360.753 262.922C360.426 263.055 360.077 263.114 359.728 263.093C355.636 262.835 347.833 263.073 342.046 267.665C334.413 273.706 323.936 277.256 309.01 277.256L298.475 258.698Z" fill="#CEB189"/>
            <path d="M236.243 277.546L239.218 255.895C286.45 270.147 306.746 235.113 369.753 269.52L371.705 277.543C375.732 294.099 362.033 309.347 346.627 305.448L236.243 277.546Z" fill="#B39A78"/>
            <path d="M234.425 74.4922C234.592 72.651 236.089 71.1925 237.761 70.8127C238.712 70.6291 239.692 70.7511 240.579 71.1637C240.904 71.2989 240.909 71.2961 240.579 71.1637C240.313 68.2035 240.186 65.0188 240.21 61.5724C240.319 45.2607 252.155 32.1309 267.231 32.2488L267.295 32.5969V32.2488C282.372 32.1309 294.208 45.2607 294.317 61.5724C294.341 65.0246 294.2 68.2035 293.947 71.1637C293.617 71.2961 293.623 71.2989 293.947 71.1637C294.835 70.7511 295.814 70.6291 296.765 70.8127C298.437 71.1925 299.934 72.651 300.102 74.4922C300.314 76.8541 296.289 85.4501 296.289 85.4501C295.226 87.9702 290.669 88.7181 290.669 88.7181C287.622 113.171 273.333 118.398 267.274 118.398C261.215 118.398 246.915 113.174 243.879 88.7181C243.879 88.7181 239.332 87.9702 238.258 85.4501C238.237 85.4501 234.225 76.8541 234.425 74.4922Z" fill="#CEB189"/>
            <path d="M112.931 62.1844C108.005 67.9755 110.826 77.3683 117.956 78.919C120.251 79.4169 122.289 80.8306 123.665 82.8793C125.041 84.928 125.654 87.4618 125.384 89.9775V90.0005C124.546 97.814 131.921 103.617 138.53 100.343H138.554C140.681 99.2898 143.095 99.1395 145.319 99.9217C147.543 100.704 149.415 102.361 150.563 104.566V104.592C154.131 111.444 163.247 111.444 166.815 104.592V104.566C167.963 102.361 169.834 100.704 172.059 99.9217C174.283 99.1395 176.697 99.2898 178.824 100.343H178.847C185.457 103.617 192.832 97.814 191.994 90.0005V89.9775C191.728 87.4617 192.347 84.9297 193.726 82.8843C195.106 80.8389 197.145 79.4296 199.441 78.9362C206.571 77.3856 209.392 68.0043 204.465 62.2017C202.88 60.3384 201.999 57.898 201.999 55.3649C201.999 52.8318 202.88 50.3914 204.465 48.5281C209.392 42.7399 206.571 33.3471 199.441 31.7965C197.146 31.2979 195.109 29.8841 193.733 27.8355C192.357 25.787 191.743 23.2535 192.013 20.7379V20.712C192.85 12.8986 185.475 7.09888 178.866 10.3698H178.842C176.716 11.4217 174.303 11.5721 172.08 10.7911C169.857 10.0101 167.986 8.35476 166.836 6.1524V6.12363C163.268 -0.726105 154.152 -0.726105 150.584 6.12363V6.1524C149.435 8.35597 147.564 10.0125 145.34 10.794C143.116 11.5755 140.702 11.4252 138.576 10.3727H138.552C131.943 7.09887 124.568 12.9014 125.405 20.7293V20.7552C125.675 23.2708 125.061 25.8043 123.685 27.8528C122.309 29.9013 120.272 31.3152 117.977 31.8137C110.847 33.3615 108.026 42.7457 112.952 48.5454C114.526 50.4095 115.397 52.8444 115.393 55.3694C115.389 57.8944 114.511 60.326 112.931 62.1844Z" fill="#000E40"/>
            <path d="M226.63 152.584L207.448 147.405C205.688 146.928 203.97 146.282 202.315 145.475C195.961 142.821 190.499 138.147 186.646 132.07C182.794 125.992 180.732 118.795 180.733 111.425C180.733 106.905 138.281 106.905 138.281 111.425C138.28 118.795 136.218 125.992 132.365 132.07C128.513 138.148 123.05 142.821 116.696 145.475C115.041 146.282 113.323 146.928 111.562 147.405L92.3806 152.584C85.6283 154.41 79.634 158.626 75.3506 164.562C71.0672 170.498 68.7406 177.814 68.7407 185.345V273.318L82.3286 290.712V379.894H236.685V293.908L250.27 264.461V185.348C250.271 177.816 247.944 170.5 243.661 164.563C239.377 158.626 233.383 154.41 226.63 152.584Z" fill="#CEB189"/>
            <path d="M226.63 152.584L207.448 147.405C205.688 146.928 203.97 146.282 202.315 145.475C195.961 142.821 190.499 138.147 186.646 132.07C182.794 125.992 180.732 118.795 180.733 111.425C180.733 106.905 138.281 106.905 138.281 111.425C138.28 118.795 136.218 125.992 132.365 132.07C128.513 138.148 123.05 142.821 116.696 145.475C115.041 146.282 113.323 146.928 111.562 147.405L92.3806 152.584C85.6283 154.41 79.634 158.626 75.3506 164.562C71.0672 170.498 68.7406 177.814 68.7407 185.345V273.318L82.3286 290.712V379.894H236.685V293.908L250.27 264.461V185.348C250.271 177.816 247.944 170.5 243.661 164.563C239.377 158.626 233.383 154.41 226.63 152.584Z" fill="#000E40"/>
            <path d="M116.754 145.428C122.1 151.997 128.677 157.26 136.048 160.865C143.42 164.469 151.416 166.334 159.505 166.334C167.594 166.334 175.591 164.469 182.962 160.865C190.334 157.26 196.911 151.997 202.256 145.428C195.915 142.767 190.465 138.094 186.622 132.022C182.779 125.95 180.723 118.763 180.722 111.403C180.722 106.884 181.761 94.9336 181.761 94.9336C168.838 108.101 150.042 109.139 137.364 95.6902C137.364 95.6902 138.265 106.884 138.265 111.403C138.266 118.76 136.213 125.945 132.374 132.017C128.536 138.088 123.091 142.763 116.754 145.428Z" fill="#B39A78"/>
            <path d="M111.562 147.385L92.3806 152.564C85.6283 154.39 79.634 158.606 75.3506 164.542C71.0672 170.478 68.7406 177.794 68.7407 185.325V286.239L82.3286 290.703V379.885H116.696V145.455C115.041 146.262 113.323 146.908 111.562 147.385Z" fill="#FF4E35"/>
            <path d="M207.656 147.385L226.837 152.564C233.589 154.391 239.582 158.607 243.865 164.543C248.148 170.479 250.474 177.794 250.474 185.325V286.239L236.889 290.703V379.885H202.522V145.455C204.177 146.262 205.895 146.908 207.656 147.385Z" fill="#FF4E35"/>
            <path d="M136.061 272.594C135.425 272.502 115.856 267.07 106.888 256.673C97.5675 245.877 88.4327 242.289 80.9568 240.753C80.3223 240.622 79.6655 240.741 79.1052 241.089C78.5449 241.437 78.1181 241.99 77.9022 242.649C77.7721 243.043 77.7216 243.462 77.7541 243.879C77.7865 244.296 77.9013 244.701 78.0905 245.065C78.2797 245.43 78.539 245.747 78.851 245.994C79.1629 246.241 79.5202 246.413 79.8987 246.498C83.844 247.361 91.0434 250.048 98.6948 258.23L87.6804 251.665C85.8875 250.596 83.7815 250.32 81.8049 250.897L69.3948 254.711C68.7513 254.909 68.1994 255.359 67.8476 255.975C67.4958 256.59 67.3696 257.326 67.4939 258.037C67.6228 258.781 68.0163 259.441 68.5899 259.875C69.1635 260.31 69.8715 260.483 70.5619 260.359L81.462 258.411C83.1304 258.14 84.835 258.454 86.3271 259.306L95.3662 264.55C95.3662 264.55 86.3697 259.288 70.7586 263.687C70.1255 263.864 69.5645 264.264 69.1638 264.823C68.763 265.382 68.545 266.069 68.544 266.777C68.5435 267.255 68.6426 267.727 68.8337 268.158C69.0249 268.589 69.3033 268.968 69.6484 269.267C69.9934 269.566 70.3961 269.776 70.8267 269.884C71.2573 269.991 71.7047 269.992 72.1357 269.887C76.4931 268.808 84.4475 267.815 93.9359 271.141L86.0188 270.43C83.208 270.177 80.3785 270.336 77.6071 270.902L74.2121 271.593C73.6486 271.707 73.1381 272.026 72.7646 272.497C72.391 272.967 72.1767 273.562 72.157 274.182C72.1461 274.561 72.2083 274.939 72.3396 275.291C72.471 275.643 72.6687 275.961 72.92 276.226C73.1714 276.49 73.4709 276.695 73.7996 276.828C74.1282 276.96 74.4787 277.017 74.8289 276.995C78.9204 276.736 86.7259 276.975 92.5136 281.567C100.144 287.608 110.621 291.158 125.549 291.158L136.061 272.594Z" fill="#B39A78"/>
            <path d="M113.936 287.133L117.464 267.484C163.242 278.764 188.764 257.812 250.485 285.093V301.699C250.485 317.697 236.11 329.08 222.02 324.241L113.936 287.133Z" fill="#B39A78"/>
            <path d="M190.585 78.2142C190.334 77.1297 189.73 76.1792 188.885 75.5368C188.039 74.8944 187.008 74.603 185.98 74.716C185.98 74.716 185.98 74.693 185.98 74.6815V74.6987C186.309 71.2515 186.463 67.7875 186.443 64.322C186.371 48.0075 174.562 34.8489 159.485 34.9266C144.411 34.8086 132.572 47.9385 132.466 64.253C132.44 67.4548 132.564 70.656 132.836 73.8443C133.163 73.9767 133.16 73.9795 132.836 73.8443C131.949 73.4289 130.968 73.3068 130.018 73.4934C128.343 73.8702 126.846 75.3288 126.681 77.1699C126.468 79.5318 130.491 88.1307 130.491 88.1307C131.554 90.6479 136.111 91.3988 136.111 91.3988C136.661 93.2025 138.001 97.8831 138.504 99.4539C140.971 107.141 146.158 111.801 150.092 115.947C153.703 119.753 158.528 119.81 159.397 119.793C160.267 119.776 165.087 119.767 168.702 115.97C172.645 111.836 177.842 107.19 180.325 99.5085C180.684 98.401 182.484 92.6761 182.484 92.6761C184.414 92.5121 186.456 91.9827 187.389 89.3994L190.205 81.583C190.268 81.4507 190.33 81.3126 190.383 81.1716L190.415 81.0997C190.756 80.1826 190.816 79.1709 190.585 78.2142Z" fill="#CEB189"/>
            <path d="M159.267 33.03C144.193 32.915 131.884 46.485 131.786 62.7966C131.762 66.2488 132.849 73.8379 132.849 73.8379C134.588 60.7339 142.074 66.8759 140.078 50.3917C140.078 50.3917 154.561 49.8365 154.046 35.4725C154.046 35.4725 159.52 46.951 175.466 44.8883C173.698 65.8892 185.656 65.4318 185.996 74.675C186.302 71.5105 187.464 66.5422 187.448 62.7966C187.36 46.485 174.341 32.9523 159.267 33.03Z" fill="#000E40"/>
            <path d="M189.992 136.519H190.713C193.272 136.522 195.725 137.624 197.535 139.582C199.344 141.54 200.361 144.196 200.363 146.965V200.474H202.554V146.965C202.551 143.567 201.303 140.309 199.083 137.907C196.863 135.504 193.853 134.152 190.713 134.148H188.083C188.083 134.148 189.115 135.555 189.992 136.519Z" fill="#A6E1FF"/>
            <path d="M130.927 134.148H129.563C125.909 134.153 122.405 135.726 119.821 138.521C117.237 141.317 115.783 145.108 115.778 149.062V154.108H117.969V149.062C117.973 145.841 119.12 142.744 121.174 140.412C123.228 138.081 126.031 136.692 129.005 136.533C129.005 136.533 130.121 135.285 130.927 134.148Z" fill="#A6E1FF"/>
            <path d="M133.12 93.9022C134.831 93.9022 136.217 92.4017 136.217 90.5507C136.217 88.6997 134.831 87.1992 133.12 87.1992C131.41 87.1992 130.023 88.6997 130.023 90.5507C130.023 92.4017 131.41 93.9022 133.12 93.9022Z" fill="#FF4E35"/>
            <path d="M184.866 93.9022C186.577 93.9022 187.963 92.4017 187.963 90.5507C187.963 88.6997 186.577 87.1992 184.866 87.1992C183.156 87.1992 181.769 88.6997 181.769 90.5507C181.769 92.4017 183.156 93.9022 184.866 93.9022Z" fill="#FF4E35"/>
            <path d="M184.949 269.616C185.571 269.78 204.58 267.947 216.851 262.599C229.517 257.079 239.066 257.421 246.542 258.972C247.175 259.104 247.744 259.476 248.145 260.023C248.546 260.569 248.754 261.252 248.73 261.949C248.717 262.367 248.622 262.777 248.45 263.151C248.278 263.526 248.034 263.856 247.734 264.119C247.434 264.383 247.085 264.573 246.711 264.679C246.337 264.784 245.947 264.801 245.567 264.728C241.6 263.972 233.996 263.595 224.099 268.155L236.573 266.428C238.604 266.147 240.657 266.73 242.299 268.054L252.556 276.543C253.088 276.982 253.449 277.621 253.567 278.334C253.685 279.046 253.552 279.781 253.194 280.393C252.821 281.033 252.23 281.49 251.55 281.665C250.869 281.84 250.152 281.72 249.552 281.33L240.074 275.188C238.616 274.273 236.925 273.886 235.249 274.084L225.05 275.358C225.05 275.358 235.206 274.046 248.225 284.36C248.754 284.776 249.139 285.371 249.322 286.051C249.504 286.73 249.473 287.456 249.233 288.114C249.071 288.559 248.818 288.958 248.494 289.283C248.169 289.608 247.781 289.849 247.359 289.99C246.937 290.13 246.49 290.166 246.054 290.094C245.617 290.022 245.2 289.845 244.835 289.575C241.151 286.837 234.092 282.746 224.131 282.058L231.734 284.55C234.434 285.433 237.011 286.706 239.396 288.335L242.32 290.332C242.806 290.664 243.172 291.165 243.359 291.752C243.546 292.339 243.543 292.978 243.349 293.563C243.231 293.922 243.044 294.25 242.801 294.526C242.558 294.802 242.264 295.02 241.939 295.166C241.613 295.313 241.263 295.383 240.911 295.374C240.558 295.365 240.212 295.276 239.893 295.113C236.171 293.246 228.839 290.361 221.897 292.326C212.741 294.915 202.083 295.525 188.203 289.584L184.949 269.616Z" fill="#CEB189"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M124.43 157.557C122.034 156.483 119.467 155.931 116.874 155.931C114.281 155.931 111.714 156.484 109.319 157.558C106.924 158.631 104.747 160.205 102.914 162.189C101.081 164.173 99.6271 166.528 98.6352 169.12C97.6433 171.712 97.1329 174.49 97.1332 177.295V200.48H93.7637V177.296C93.7637 177.296 93.7637 177.296 93.7637 177.296C93.7633 174.012 94.3608 170.759 95.522 167.725C96.6832 164.69 98.3854 161.933 100.531 159.611C102.677 157.288 105.225 155.446 108.029 154.189C110.833 152.932 113.839 152.285 116.874 152.285M116.874 152.285C119.909 152.285 122.915 152.931 125.719 154.188C128.523 155.445 131.071 157.287 133.218 159.61C135.364 161.932 137.067 164.69 138.228 167.724C139.389 170.759 139.987 174.011 139.987 177.296C139.987 177.296 139.987 177.295 139.987 177.296V200.48H136.617V177.296C136.617 174.49 136.107 171.711 135.115 169.119C134.123 166.527 132.668 164.172 130.835 162.188C129.002 160.204 126.825 158.63 124.43 157.557" fill="#A6E1FF"/>
            <path d="M138.302 205.274C140.325 205.274 141.965 203.499 141.965 201.31C141.965 199.121 140.325 197.346 138.302 197.346C136.278 197.346 134.638 199.121 134.638 201.31C134.638 203.499 136.278 205.274 138.302 205.274Z" fill="#E5FF01"/>
            <path d="M201.459 209.313C205.543 209.313 208.855 205.73 208.855 201.31C208.855 196.89 205.543 193.307 201.459 193.307C197.374 193.307 194.062 196.89 194.062 201.31C194.062 205.73 197.374 209.313 201.459 209.313Z" fill="#E5FF01"/>
            <path d="M95.4487 205.274C97.472 205.274 99.1122 203.499 99.1122 201.31C99.1122 199.121 97.472 197.346 95.4487 197.346C93.4254 197.346 91.7852 199.121 91.7852 201.31C91.7852 203.499 93.4254 205.274 95.4487 205.274Z" fill="#E5FF01"/>
            <path d="M271.094 94.6602C269.233 94.6602 268.718 94.8184 267.064 94.8184C265.41 94.8184 264.897 94.6573 263.432 94.6602C261.922 94.6602 259.53 95.1723 257.411 95.6211C257.411 95.6211 261.064 102.643 267.263 102.643C273.463 102.643 277.116 95.6211 277.116 95.6211C274.997 95.1723 272.604 94.6602 271.094 94.6602Z" fill="#000E40"/>
            <path d="M164.861 96.0678C164.29 95.7367 163.658 95.5448 163.011 95.5056C162.364 95.4663 161.717 95.5807 161.115 95.8406L159.61 96.4965L158.103 95.8406C157.501 95.5807 156.854 95.4663 156.207 95.5056C155.56 95.5448 154.928 95.7367 154.357 96.0678C153.105 96.7813 151.502 97.5293 149.997 97.5293C149.997 97.5293 153.581 105.544 159.61 105.544C165.64 105.544 169.221 97.5293 169.221 97.5293C167.719 97.5293 166.116 96.7727 164.861 96.0678Z" fill="#000E40"/>
            <path d="M104.307 129.519C112.124 129.519 118.461 122.662 118.461 114.203C118.461 105.744 112.124 98.8867 104.307 98.8867C96.4899 98.8867 90.1528 105.744 90.1528 114.203C90.1528 122.662 96.4899 129.519 104.307 129.519Z" fill="#FF4E35"/>
            <path d="M214.648 129.519C222.465 129.519 228.802 122.662 228.802 114.203C228.802 105.744 222.465 98.8867 214.648 98.8867C206.831 98.8867 200.494 105.744 200.494 114.203C200.494 122.662 206.831 129.519 214.648 129.519Z" fill="#FF4E35"/>
            <path d="M256.134 47.578C256.134 47.578 262.353 60.846 290.515 60.449C290.515 60.449 294.317 61.5853 293.947 71.1767L291.384 48.2541L257.405 43.1074L256.134 47.578Z" fill="#A6E1FF"/>
            <path d="M267.263 44.8534V25.5239L267.295 25.6965V25.3484C282.372 25.2305 294.208 38.3719 294.309 54.6749C294.333 58.1271 294.192 68.196 293.939 71.162L292.049 64.597C290.402 58.8754 287.108 53.8733 282.647 50.3213C278.187 46.7693 272.794 44.8534 267.255 44.8534H267.263Z" fill="#000E40"/>
            <path d="M267.263 44.8534V25.5239L267.231 25.6965V25.3484C252.155 25.2305 240.319 38.3603 240.21 54.6749C240.186 58.1271 240.327 68.196 240.579 71.162L242.469 64.597C244.116 58.8754 247.411 53.8733 251.871 50.3213C256.332 46.7693 261.725 44.8534 267.263 44.8534Z" fill="#000E40"/>
            <path d="M376.065 113.945C375.493 113.615 374.862 113.423 374.215 113.384C373.568 113.346 372.921 113.46 372.319 113.721L370.811 114.374L369.307 113.721C368.704 113.46 368.057 113.346 367.409 113.384C366.762 113.423 366.13 113.614 365.558 113.945C364.306 114.659 362.703 115.407 361.201 115.407C361.201 115.407 364.782 123.424 370.811 123.424C376.841 123.424 380.425 115.407 380.425 115.407C378.92 115.407 377.317 114.659 376.065 113.945Z" fill="#000E40"/>
            <path d="M370.812 62.7974C370.812 72.1902 374.494 79.8886 382.669 79.8886C390.07 79.8886 384.923 91.0392 395.728 91.0392L390.277 66.4711L372.409 55.4902C372.409 55.4902 370.812 56.454 370.812 62.7974Z" fill="#000E40"/>
            <path d="M370.638 62.7974C370.638 72.1902 366.956 79.8886 358.781 79.8886C351.38 79.8886 356.527 91.0392 345.72 91.0392L351.172 66.4711L369.041 55.4902C369.041 55.4902 370.638 56.454 370.638 62.7974Z" fill="#000E40"/>
            <path d="M370.811 66.4718V48.4196L370.84 48.5836V48.2585C384.915 48.1492 395.969 60.4074 396.07 75.6402C396.091 78.8594 395.964 88.2724 395.725 91.0399L393.959 84.9065C392.422 79.5637 389.346 74.8928 385.181 71.5761C381.016 68.2595 375.98 66.471 370.809 66.4718H370.811Z" fill="#FF4E35"/>
            <path d="M370.811 66.4718V48.4196L370.782 48.5836V48.2585C356.692 48.1492 345.651 60.4074 345.55 75.6402C345.528 78.8594 345.659 88.2724 345.895 91.0399L347.661 84.9065C349.198 79.5639 352.274 74.8932 356.439 71.5767C360.604 68.2601 365.64 66.4714 370.811 66.4718Z" fill="#FF4E35"/>
        </svg>
    )
}

export default ImgIllustration
