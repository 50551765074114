import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'

export const GroupLinkDiv = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  &:first-child {
    padding-left: 0;
  }

  ${media.lessThan('medium')`
    padding: 0;
    margin-bottom: 2.4rem;
  `}
`

export const TextHeader = styled.div`
  color: var(--pink);
  text-transform: uppercase;
  font-size: 14px;
  height: 3.3rem;
`

export const LineList = styled.li`
  font-size: 14px;
  display: table;
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LinkItem = styled(Link)`
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;

  ${media.lessThan('medium')`
    font-weight: 500;
  `}

  &:hover {
    text-decoration: underline;
  }

  &.nam {
    color: var(--pink);

    p {
      padding: 0;
    }
  }
`

export const LinkNam = styled.div`
  color: var(--pink);
  
  ${media.lessThan('medium')`
    font-weight: 500;
  `}

  p {
    padding: 0;
  }
`

export const FooterTitleTxt = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 0;
`

export const LinkItemCommon = styled.a`
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;
  cursor: pointer;

  ${media.lessThan('medium')`
    font-weight: 500;
  `}

  &:hover {
    text-decoration: underline;
  }
`

export const LinksHolder = styled.ul`
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;;
  opacity: ${props => props.selected ? 1 : 0};
  visibility: ${props => props.selected ? "visible" : "hidden"};
  max-height: ${props => props.selected ? "100%" : 0};
  transition-delay: 0s;

  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr
  `}
`