const formatPhoneMask = phoneValue => {
  let phone = phoneValue
  phone = phone.replace(/\D/g, '')
  phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2')
  phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')
  if (phone.length === 15) {
    phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')
  }

  return phone
}

const formatIdMask = idValue => {
  let id = idValue
  id = id.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
  id = id.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
  id = id.replace(/(\d{3})(\d)/, '$1.$2')
  id = id.replace(/(\d{3})(\d{1,2})/, '$1-$2')
  id = id.replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

  return id
}

module.exports = {
  formatPhoneMask,
  formatIdMask
}
