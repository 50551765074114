import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { ContainerDasa } from '../shared/container'
import { dasTokens } from '@dasa-health/alma-tokens'

export const ImgAbsoluteContainer = styled.div`
  position: relative;
  max-width: 1366px;
  margin: 0 auto;

  ${media.lessThan('medium')`
      margin: 4rem auto;
  `}

  ${media.between('medium', 'large')`
    max-width: 877px;
  `}
`

export const TitleContainer = styled(ContainerDasa)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8.8rem;
  width: 100%;
  margin: 6.7rem auto 8.8rem;
  max-width: 1094px;
  
  ${media.lessThan('medium')`
    margin: 6.9rem 0 4rem;
    padding: 4rem 1.6rem 0;
  `}
  
  ${media.between('medium', 'large')`
    max-width: 832px;
    margin: 6.7rem auto 8.2rem;
  `}
`

export const TitleDasaContainer = styled.div`
  width: 64.7rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

export const SubTitle = styled.h2`
  font-size: 64px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -1px;
  color: var(--white);
  margin: 1.6rem 0 0;

  &:last-child {
    margin: 0 0 1.6rem;
  }

  ${media.lessThan('medium')`
    max-width: 20rem;
    font-size: 40px;

    &:last-child {
      max-width: 28.8rem;
    }
  `}
    
  ${media.between('medium', 'large')`
    font-size: 48px;
  `}

  @media (min-width: 768px) and (max-width: 768px) {
    max-width: 42.6rem;
  }
`

export const TitleDasa = styled.h1`
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${dasTokens.ColorBrandBase02};
  margin: 0;
`;

export const Paragraph = styled.p`
  max-width: 586px;
  min-height: 64px;
  font-size: 24px;
  font-weight: 400;
  line-height: 133%;
  color: ${dasTokens.ColorBrandBase02};
  margin: 0 0 4rem 0;

  ${media.between('medium', 'large')`
    max-width: 442px;
  `}

  ${media.lessThan('medium')`
    font-size: 14px;
    line-height: 170%;
    min-height: initial;
    max-width: 300px;
  `}
`

export const LinkExams = styled(Link)`
  display: flex;
  align-items: center;
  background-color: var(--pink);
  color: var(--white);
  padding: 1.2rem 1.6rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  min-width: 93px;
  text-decoration: none;
  border-radius: 4px;

  svg {
    width: 17.55px;
    height: 18.31px;
    margin-right: 1.084rem;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 12rem;
  right: 10.9rem;

  ${media.lessThan('medium')`
    display: flex;
    justify-content: center;
    position: inherit;
  `}
`;

export const IllustrationContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 662.29px;
    height: 684px;

    ${media.between('medium', 'large')`
      width: 474.68px;
      height: 488px;
      right: -144px;
    `}

    @media (min-width: 577px) and (max-width: 767px) {
      width: 474.68px;
      height: 488px;
      right: -144px;
    }

    @media (max-width: 576px) {
        display: none;
    }

    .gatsby-image-wrapper {
      div {
        padding-bottom: 103.3% !important;
      }
    }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const ResultsNav = styled.div``;

export const LinkNav = styled.p`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: var(--white);
  text-decoration: none;
  margin: 0 0 0 1.6rem;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 2.4rem;
  }

  .gatsby-image-wrapper {
    margin-left: .5rem;
  }
`;

export const LogoNavWhiteDiv = styled.div`
  width: 3.6rem;
  height: .9rem;
  margin-left: .5rem;
`;

export const BannerImgContainer = styled.div`
  @media (max-width: 768px) {
      display: none;
  }
`;

export const BannerImg = styled(Img)`
  width: 42.3rem;
  height: 24rem;
  border-radius: 4px;
`;

export const BannerImgMobileContainer = styled.div`
  display: none;

  img {
    width: 288px;
    height: 240px;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`

export const BannerImgMobile = styled(Img)`
  width: 28.8rem;
  height: 24rem;
  border-radius: 4px;
`

export const Illustration = styled(Img)``

export const BannerLink = styled.a``

export const AboutLink = styled(Link)`
  text-decoration: underline;
  color: ${dasTokens.ColorBrandBase02};
  margin-left: .6rem
`;