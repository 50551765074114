import React from 'react'
import { FooterStyled } from './styled'
import InnerContainer from './InnerContainer'

const Footer = () => {
  return (
    <FooterStyled>
      <InnerContainer />
    </FooterStyled>
  )
}

export default Footer
