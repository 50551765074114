const removeSpecialCharacters = str => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase() // Remove acentos
      .replace(/[ÀÁÂÃÄÅ]/g,"A")
      .replace(/[àáâãäå]/g,"a")
      .replace(/[ÈÉÊË]/g,"E")
      .replace(/[^a-z0-9]/gi,'')
  }
  
  module.exports = removeSpecialCharacters