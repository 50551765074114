import styled from 'styled-components'

export const LogoContainer = styled.div`
  display: flex;
  width: 100%;
`

export const ImgNav = styled.div`
  width: 3.6rem;
  cursor: pointer;
`;
