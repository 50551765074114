import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@dasa-health/alma-react'
import ModalNavContext from './contexts/ModalNavContext'

const Theme = ({ children }) => {
  const [timerModalNav, setTimerModalNav] = useState(false)

  return (
    <ThemeProvider>
      <ModalNavContext.Provider 
        value={{timerModalNav, setTimerModalNav}}
      >
        {children}
      </ModalNavContext.Provider>
    </ThemeProvider>
  )
}

Theme.propTypes = {
  children: PropTypes.element,
}

export default Theme
