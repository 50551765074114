import styled from 'styled-components'
import { Breadcrumb as BreadcrumbPlugin } from 'gatsby-plugin-breadcrumb'
import media from 'styled-media-query'
import { Container } from '../shared/container'
import { alvTokens } from '@dasa-health/alma-tokens'

export const BreadcrumbHolder = styled(Container)`
  padding: 4.4rem 0 3.2rem 0;
  margin: 67px auto 0;
  max-width: 1092px;
  
  &.about {
    padding: 4.4rem 0 0;
  }

  ${media.between('medium', 'large')`
    padding: 4.4rem 1.6rem 3.2rem;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    margin: 70px 0 0 0;
    padding: 3.2rem 1.6rem 2.4rem;  
  `}

  .breadcrumb {
    font-size: 12px;
    color: ${alvTokens.ColorPaletteGray30};
    display: inline-block;
    text-decoration: none solid rgb(125, 125, 125);
    line-height: 133%;
    font-weight: 500;
    text-transform: capitalize;

    a {
      text-decoration: none;
      color: var(--pink);
    }

    .breadcrumb__separator {
      display: inline-block;
      font-size: 0;

      &:after {
        content: " | ";
        font-size: 12px;
        margin: 0 1.2rem;
      }
    }

    &:last-of-type > a {
      color: ${alvTokens.ColorPaletteGray30};
      cursor: default;
    }
  }
`

export const Breadcrumb = styled(BreadcrumbPlugin)``
