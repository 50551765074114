import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../../shared/container'

const InnerDiv = styled(Container)`
    margin: 0 auto;
    width: 100%;
    max-width: 1366px;

    ${media.lessThan('medium')`
        margin: 0;
        max-width: 100%;
    `}
`

export default InnerDiv
