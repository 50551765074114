import React, {useContext} from 'react'
import LogoNav from '../../shared/LogoNavDasa/logoNav'
import ImgIllustration from './ImgIllustration'
import { Close } from '@dasa-health/alma-icons'
import * as S from './styled'
import ModalNavContext from '../../../utils/contexts/ModalNavContext'
import modalNav from '../modalNav'

const ResultsNav = ({data, slug}) => {
    const { 
        timerModalNav,
        setTimerModalNav
    } = useContext(ModalNavContext)

    function cancelTimerModalNav() {
      if(!timerModalNav) return 

      clearTimeout(timerModalNav)
      setTimerModalNav(false)
    }

    function closeModalNav(e, modal) {
        if(timerModalNav) return
      
        setTimerModalNav(
            setTimeout(function() {
                modalNav(e, modal)
            }, 1000)
        )
    }

    function onlyCloseModalNav(e, modal) {
        setTimerModalNav(true)
        modalNav(e, modal)
        cancelTimerModalNav()
    }

    return (
        <S.WhiteContainer 
            id='modalResultsNav'
            onMouseEnter={() => {cancelTimerModalNav()}}
            onMouseLeave={(e) => {closeModalNav(e, slug)}}
        >
            <S.ResultadosNavContainer id="modal-results-content">
                <S.ImgContainer>
                    {data.results_modal_img
                    ?
                        <S.ImgModal 
                            alt={data.results_modal_img.alt_text}
                            fluid={data.results_modal_img.localFile.childImageSharp.fluid}
                        />
                    :
                        <ImgIllustration />
                    }
                </S.ImgContainer>  

                <S.ResultsNav>
                    <S.LogoNavContent>
                        <LogoNav />
                    </S.LogoNavContent>

                    {data.results_modal_title && (
                        <S.TitleResultsNav>
                            {data.results_modal_title}
                        </S.TitleResultsNav>
                    )}

                    {data.results_modal_description && (
                        <S.DescriptionResultsNav>
                            {data.results_modal_description}

                            {data.results_modal_description_desk && (
                                <S.DescriptionOnlyDesk>
                                    {data.results_modal_description_desk}
                                </S.DescriptionOnlyDesk>
                            )}
                        </S.DescriptionResultsNav>
                    )}

                    {data.results_modal_btn_link && data.results_modal_btn_title && (
                        <S.ButtonViewResults 
                            href={data.results_modal_btn_link}
                            target='blank'
                        >
                            {data.results_modal_btn_title}
                        </S.ButtonViewResults>
                    )}
                </S.ResultsNav>

                <S.ButtonDiv onClick={(e) => onlyCloseModalNav(e, slug)}>
                    <S.ButtonClose>
                        <Close />
                    </S.ButtonClose>

                    <S.ButtonText>
                        {data.results_modal_btn_close_title}
                    </S.ButtonText>
                </S.ButtonDiv>
            </S.ResultadosNavContainer>
        </S.WhiteContainer>
    )
}

export default ResultsNav
