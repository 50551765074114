import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const MenuDasaToggler = styled.span`
  display: flex;
  align-items: center;
  height: 4.05rem;
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  overflow: hidden;
  

  ${media.greaterThan('medium')`
    margin-right: 2.4rem;
  `}

  &:hover {
    .menuFlags {
      height: 114px;
    }
  }
`

export const MenuItemDasa = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;
  cursor: pointer;
`

export const MenuItemDasaNavDesktop = styled.div`
    ${MenuItemDasa}
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;

    &:hover {
      .menuDropdown {
        display: flex;
      }
    }
`

export const FlagContainer = styled.div`
    margin-left: 3.2rem;

    ${media.greaterThan('medium')`
      margin-left: 0;
    `}
`

export const FlagImg = styled.div`
  min-width: 24px;
`

export const FlagTitle = styled.p`
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 0 .6rem;
`

export const InitialsFlag = styled.span`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin-left: .6rem;
  color: ${alvTokens.ColorPaletteGray30};

  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const DropdownVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 0;
  top: 5.3rem;
  right: 2rem;
  width: 18rem;
  margin: 0;
  background: var(--white);
  z-index: 100;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 8%);
  border-radius: 8px;
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  overflow: hidden;

  ${media.lessThan('medium')`
    width: auto;
    border-radius: 0;
    top: 5.2rem;
    right: -16px;
    left: -318px;
    box-shadow: none;
  `}
`

export const FlagItem = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1.6rem 1.6rem 0;
    text-decoration: none;
    color: ${alvTokens.ColorNeutralInverse20};
    padding-bottom: 1.6rem;
    border-bottom: 2px solid #ECECEC;
    
    svg {
      border-radius: 50px;
    }
    
    &:last-child {
      border: 0;
    }
    
    ${media.lessThan('medium')`
      justify-content: center;
    `}
`