const getSlugByCountry = page => {
    const country = process.env.GATSBY_COUNTRY

    const slugs = {
        "areasAtuacao": {
            "BR": "areas-de-atuacao",
            "AR": "areas-de-actuacion"
        },
        "parceriaInovacao": {
            "BR": "parceria-inovacao",
            "AR": "alianza-innovacion"
        },
        "corpoClinico": {
            "BR": "corpo-clinico",
            "AR": "cuerpo-clinico"
        },
        "ondeFazerExames": {
            "BR": "onde-fazer-exames",
            "AR": "sedes"
        },
        "blog": {
            "BR": "blog",
            "AR": "blog"
        },
        "contato": {
            "BR": "contato",
            "AR": "contactanos"
        },
        "exames": {
            "BR": "exames",
            "AR": "examenes"
        },
        "sobre": {
            "BR": "sobre",
            "AR": "conocenos"
        }
    }
      
    return slugs[page] && slugs[page][country] ? slugs[page][country] : false;
}
  
module.exports = getSlugByCountry
  