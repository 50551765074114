import React from 'react'
import CommonMenu from '../../header/MenuDesktopDasa/commomMenu'
import LogoNav from './logoNav'
import modalNav from '../../BannerNav/modalNav'
import * as S from './styled'

const Logo = ({menu}) => {
  return (
    <S.LogoContainer className="nav-dasa">
      <CommonMenu 
        menu={menu}
        addClass="nav-dasa"
      />
      <S.ImgNav onClick={e => modalNav(e, menu.slug)}>
        <LogoNav />
      </S.ImgNav>
    </S.LogoContainer>
  )
}

export default Logo
