import React from 'react'
import * as S from './styled'
import SocialLinks from '../../../../shared/SocialLinks'
import LogoDasaSvg from '../../../../shared/logoDasaSvg'

const FooterSocial = () => {
  return (
    <S.FooterSocialHolder>
      <S.SocialImageHolder>
        <S.SocialImageLink to="/" aria-label="Link para a home">

          <LogoDasaSvg 
            width="87"
            height="47"
          />
          
        </S.SocialImageLink>
      </S.SocialImageHolder>
      <SocialLinks iconsSize="20" />
    </S.FooterSocialHolder>
  )
}

export default FooterSocial
