import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'

export const FooterSocialHolder = styled.div`
  padding-top: 18.4rem;
  display: grid;
  grid-template-areas:
		"logo logo logo logo social"
		"text text text text text"
  ;

  ${media.greaterThan('medium')`
    & > *:last-child {
      grid-column: 5 / 6;
    }
  `}

  grid-template-columns: 3fr 2fr ;

  ${media.lessThan('medium')`
    grid-template-areas:
		  "logo social social"
		  "text text text"
    ;
    align-items: center;
    padding-top: 4rem;
  `}
`

export const SocialImageLink = styled(Link)``

export const SocialImageHolder = styled.div`
  max-width: 87px;
  display: inline-block;
  grid-area: logo;
  
  ${media.lessThan('medium')`
    max-width: 58px;
  `}
`

export const SocialLinksHolder = styled.div`
  grid-area: social;
`

export const SocialLinksListHolder = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0;
`
export const ListItem = styled.li`
  display: inline-block;
`
export const ListItemLink = styled.a``

export const ListItemIcon = styled.i`
  color: #7D7D7D;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 24px;
  transition: 0.3s;
  width: 22px;
  height: 25px;

  &:hover {
    color: var(--pink);
  }
`
