import { createGlobalStyle } from 'styled-components'
import { DasaSansBold, DasaSansSemiBold, DasaSansMedium, DasaSansRegular } from '../utils/fonts'
import { alvTokens } from '@dasa-health/alma-tokens'
import media from 'styled-media-query'

const GlobalStyle = createGlobalStyle`
  :root {
    --white: #FFFFFF;
    --blueBtn: #ADBFFF;
    --neutral00: #FEFEFE;
    --brand-dasa: #02103F;
    --blue: #012169;
    --pink: #D22673;
    --grayStrong: #161616;
    --gray: #EBEBEB;
    --gray2: #595959;
    --marker: 10px;
    --font10: 10px;
    --font12: 12px;
    --fontDefault: 14px;
    --font16: 16px;
    --font20: 20px;
    --font22: 22px;
    --font24: 24px;
    --fontTitle: 40px;
  }

  @font-face {
        font-family: 'Dasa Sans';
        src: url('../assets/fonts/DasaSans-Bold.eot');
        src: local("Dasa Sans Bold"), url(${DasaSansBold}) format('truetype');
        font-weight: 700;
        font-display: swap;
  }
  @font-face {
      font-family: 'Dasa Sans';
      src: local("Dasa Sans SemiBold"), url(${DasaSansSemiBold}) format('truetype');
      font-weight: 600;
      font-display: swap;
  }
  @font-face {
      font-family: 'Dasa Sans';
      src: local("Dasa Sans Medium"), url(${DasaSansMedium}) format('truetype');
      font-weight: 500;
      font-display: swap;
  }
  @font-face {
      font-family: 'Dasa Sans';
      src: local("Dasa Sans Regular"), url(${DasaSansRegular}) format('truetype');
      font-weight: 400;
      font-display: swap;
  }

  * {
    font-family: ${props => props.theme.fonts.base};
  }

  html {
    font-size: 10px;
    font-family: ${props => props.theme.fonts.base};
    body {
      margin: 0;
      padding: 0;
    }

    .wp-block-buttons {
        display: flex;
    }
  }

  .btn-dasa-blue {
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 1.2rem 0;
    border-radius: 4px;
    background: ${props => props.theme.colors.brand.primary[10]};
    color: var(--white);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    -webkit-text-decoration: none solid var(--white);
    text-decoration: none solid var(--white);
    text-transform: uppercase;
    opacity: 0.9;

    a {
      text-decoration: none;
      color: var(--white);
    }
  }

  .wp-block-buttons {
    .wp-block-button {
      &.btn-dasa-pink {
        margin-bottom: 3.2rem;
        
        ${media.lessThan('medium')`
          margin-bottom: 2.4rem;
        `}

        a:hover {
          color: var(--white);
        }
      }  
    }
  }

  .btn-dasa-pink {
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--pink);
    color: var(--white);
    padding: 1.2rem 1.6rem;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;
    border-radius: 4px;

    a {
      text-decoration: none;
      color: var(--white);
    }
  }

  .mb-3, .my-3 {
    margin-bottom: 1rem;
  }

  .mb-4, .my-4 {
    margin-bottom: 1.6rem;
  }

  .mt-3 {
    margin-top: 1rem;
  }

  .mt-4 {
    margin-top: 1.6rem;
  }

  .onlyMarginBottom {
    margin: 0 0 16px 0 !important;
  }

  .tippy-content {
    p {
      font-size: 14px;
    }
  }

  button {
    &.btn-dasa-genomica {
      color: ${alvTokens.ColorPaletteWhite};
      background-color: var(--pink);
      padding: .8rem;
      min-width: 8.2rem;
  
      &:hover:enabled:not(:active) {
        background-color: var(--pink);
        color: ${alvTokens.ColorPaletteWhite};
      }
    }
  }
`

export default GlobalStyle
