const dotenv = require('dotenv')
const redis = require('redis')
const { promisify } = require('util')

dotenv.config({
  path: '../../.env',
})

let auth
let client
let setAsync
let getAllAsync
let buffer

const initialize = () => {
  auth = process.env.REDIS_PASSWORD ? { auth_pass: process.env.REDIS_PASSWORD, tls: { servername: process.env.REDIS_HOST } } : {}
  client = redis.createClient(process.env.REDIS_PORT, process.env.REDIS_HOST, auth)
  setAsync = promisify(client.hset).bind(client)
  getAllAsync = promisify(client.hgetall).bind(client)
}

const set = async (hash, key, value) => {
  if (!client) initialize()
  await setAsync(hash, key, value)
}

const get = async (hash, key) => {
  if (!client) initialize()
  if (typeof buffer === 'undefined') {
    await loadBuffer(hash)
  }
  const result = buffer[key]
  return result
}

const loadBuffer = async hash => {
  buffer = await getAllAsync(hash)
}

module.exports = { get, set }
