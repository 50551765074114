import React from 'react'
import PropTypes from 'prop-types'

import { Linkedin } from '@styled-icons/fa-brands/Linkedin'
import { Facebook } from '@styled-icons/fa-brands/Facebook'
import { Youtube } from '@styled-icons/fa-brands/Youtube'
import { Instagram } from '@styled-icons/fa-brands/Instagram'
import IconWrapper from '../IconWrapper'

import * as S from './styled'

const SocialListItem = props => {
  const getFont = fontType => {
    const fonts = {
      linkedin: () => Linkedin,
      facebook: () => Facebook,
      youtube: () => Youtube,
      instagram: () => Instagram,
    }

    return (fonts[fontType] || fonts.linkedin)()
  }
  return (
    props.link && (
      <S.ListItem>
        <S.ListItemLink href={props.link} target="_blank" aria-label={`Ícone rede social ${props.type}`}>
          <S.ListItemIcon>
            <IconWrapper Icon={getFont(props.type)} size={props.size} />
          </S.ListItemIcon>
        </S.ListItemLink>
      </S.ListItem>
    )
  )
}

SocialListItem.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
}

SocialListItem.defaultProps = {
  size: '15',
}

export default SocialListItem
