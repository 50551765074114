import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { CircleButton } from '../../shared/CircleButton/styled';
import { navTokens, alvTokens } from '@dasa-health/alma-tokens'

export const WhiteContainer = styled.div`
    width: 100%;
    background-color: var(--white);
    margin: 6.7rem 0 -6.7rem;
    height: 0;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    overflow: hidden;
`

export const ResultadosNavContainer = styled.div`
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    position: relative;
    border-top: 1px solid #e8e8e8;

    ${media.lessThan('medium')`
        justify-content: center;
        padding: 3.2rem 0 3.175rem;
    `}
`

export const ImgContainer = styled.div`
    display: contents;

    svg {
        width: 100%;
        max-width: 462px;
        height: 32.1rem;
        margin-left: 3.8rem;
    }

    ${media.lessThan('medium')`
        display: none;
    `}
`

export const ImgModal = styled(Img)`
    width: 462px;
    height: 321px;
    margin-left: 3.8rem;
`

export const LogoNavContent = styled.div`
    width: 13.6rem;
    height: 3.4rem;
    margin-bottom: 1.6rem;

    ${media.lessThan('medium')`
        width: 9.7rem;
    `}
`

export const ResultsNav = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 647px;
    margin: 5.4rem 0 4.3rem 7.721rem;

    ${media.lessThan('medium')`
        width: 100%;
        padding: 0 1.6rem;
        margin: 0;
        align-items: center;
    `}
`

export const TitleResultsNav = styled.p`
    margin: 0 0 1.6rem;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: ${navTokens.ColorBrandPrimary60};

    ${media.lessThan('medium')`
        max-width: 26.4rem;
        text-align: center;
        font-size: 16px;
    `}
`

export const DescriptionResultsNav = styled.div`
    margin: 0 0 1.6rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${alvTokens.ColorPaletteGray30};

    ${media.lessThan('medium')`
        font-size: 14px;
        line-height: 170%;
        text-align: center;
        margin: 0 auto 1.6rem;
        max-width: 26.8rem;
    `}
`

export const DescriptionOnlyDesk = styled.p`
    display: none;

    ${media.greaterThan('medium')`
        display: inline;
        margin: 0 0 0 .5rem;
    `}
`

export const ButtonViewResults = styled.a`
    text-decoration: none;
    background-color: ${navTokens.ColorBrandPrimary60};
    color: var(--white);
    padding: 1.2rem 1.6rem;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    border-radius: 4px;
    cursor: pointer;

    ${media.lessThan('medium')` 
        font-size: 14px;
        line-height: 115%;
        margin-bottom: 4rem;
    `}
`

export const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    height: 2.4rem;
    position: absolute;
    top: 37px;
    right: 34px;
    cursor: pointer;

    ${media.lessThan('medium')`
        top: auto;
        bottom: 3.175rem;
        right: calc((100vw - 5.2rem) / 2);
    `}
`

export const ButtonClose = styled(CircleButton)`
    width: 2rem;
    height: 2rem;
    background-color: var(--pink);
    
    svg {
        font-size: 1.6rem;
    }

    ${media.lessThan('medium')`
        display: none;
    `}
`

export const ButtonText = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 133%;
    margin-left: .6rem;
    color: ${alvTokens.ColorPaletteGray30};

    ${media.lessThan('medium')`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.3rem;
        height: 3.2rem;
        margin: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 115%;
    `}
`