import React from 'react'
import { Link } from 'gatsby'
import * as S from './styled'
import LogoDasaSvg from '../../shared/logoDasaSvg'
import { isMobile } from '@cms/utils'

const Logo = () => {
  const mobile = isMobile()

  return (
    <>
      <S.LogoContainer>
        <Link to="/" alt="Link para a home" aria-label="Link para a home">
          <LogoDasaSvg
            width={!mobile ? "73" : "52"}
            height={!mobile ? "39" : "29"}
          />
        </Link>
      </S.LogoContainer>
    </>
  )
}

export default Logo
