import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const VerticalSubmenu = ({ submenu }) => (
  <S.VerticalSubmenuWrapper data-testid="vertical-submenu-item">
    {submenu.map((item, index) => (
      <S.VerticalSubmenuItem key={index} to={item.url}>
        {item.title}
      </S.VerticalSubmenuItem>
    ))}
  </S.VerticalSubmenuWrapper>
)

VerticalSubmenu.propTypes = {
  submenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
}

export default VerticalSubmenu
