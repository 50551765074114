import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import VerticalSubMenuDasa from './verticalSubMenuDasa'
import { ChevronDown } from '@dasa-health/alma-icons'
import * as S from './styled'

const DropdownMenu = ({ menu }) => {
  const handleItemClick = () => {
    const redirectTo = menu.url;
    navigate(redirectTo)
  }

  return (
      <S.MenuItemDasaNavDesktop data-testid="dropdown-menu">
        <S.MenuDasaToggler data-testid="dropdown-menu-button" onClick={handleItemClick}>
          {menu.title}
          <S.ArrowIconContainer>
            <ChevronDown />
          </S.ArrowIconContainer>
        </S.MenuDasaToggler>

        <VerticalSubMenuDasa submenus={menu.wordpress_children} />
      </S.MenuItemDasaNavDesktop>
  )
}

DropdownMenu.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    submenus: VerticalSubMenuDasa.propTypes.submenus,
  })
}

export default DropdownMenu
