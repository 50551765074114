import styled from 'styled-components'
import media from 'styled-media-query'

export const ContactUsDiv = styled.div`
  color: #7D7D7D;
  padding: 0 1rem;
  min-width: 20.4rem;
  word-break: break-word;

  ${media.lessThan('medium')`
    max-width: 100%
  `}

  &:last-child {
    padding-right: 0;
    min-width: 227px;
  }

  li {
    margin-bottom: 1.5rem;
    clear: both;
  }

  ${media.lessThan('medium')`
    padding: 0;
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    ul {
      grid-template-columns: 1fr;
    }
  `}

  &.nam {
    margin-top: 2.4rem;
    padding: 0;

    div {
      margin-bottom: 1.6rem; 

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${media.lessThan('medium')`
      margin-top: 0;
    `}

    .contact-footer {
      ${media.lessThan('medium')`
        margin-bottom: 1.6rem;
      `}
    }
  }
`

export const ContactUsHeader = styled.div`
  color: var(--pink);
  text-transform: uppercase;
  font-size: 14px;

  li {
    margin-top: 1rem;
  }

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`

export const ContactUsIcon = styled.div`
  float: left;
  i {
    color: var(--pink);
    font-size: 14px;
    padding-right: 1.8rem;
  }
`

export const ContactUsData = styled.div`
  float: left;
  font-size: 16px;
`

export const ContactUsPhone = styled.div`
  font-size: 20px;
`

export const ContactUsPeriod = styled.div`
  font-size: 12px;
  margin-top: 1.8rem;
`

export const ContactInfoHolder = styled.div`
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.lessThan('medium')`
    grid-template-columns: .5fr 5fr;
    margin-bottom: 1.6rem;
  `}
`

export const ContactInfoIconHolder = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;

  span {
    ${media.lessThan('medium')`
      font-size: 14px;
      line-height: 15px;
    `}
  }
`
export const ContactInfoIcon = styled.i`
  display: flex;
  color: #D12673;
  margin-right: 3px;
  font-size: 1.6rem;
`
export const ContactInfoTextHolder = styled.div`
  font-size: 12px;
`
export const ContactInfoTitle = styled.span`
  display: block;
  font-size: ${props => (props.lowerWords ? '14px' : '16px')};
  margin: .8rem 0;

  ${media.lessThan('medium')`
    margin: 0;
  `}
`
export const ContactInfoText = styled.span`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  
  ${media.lessThan('medium')`
    font-size: 12px;
    padding-left: 3.2rem;
    color: ${props => props.theme.colors.brand.primary[10]};
    margin: 0;
  `}
`
export const ContactInfoSubtext = styled.span`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;

  ${media.lessThan('medium')`
    padding-left: 3.2rem;
    font-size: 12px;
    color: ${props => props.theme.colors.brand.primary[10]};
  `}
`

export const Contact = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 115%;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 0;
  text-decoration: none;
`;

export const SubtitleItem = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 0;

  ${media.lessThan('medium')`
    padding-left: 3.2rem;
    color: ${props => props.theme.colors.brand.primary[10]};
  `}
`