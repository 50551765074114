import React from 'react'
import InnerDiv from './styled'
import FooterLinks from './FooterLinks'

const InnerContainer = () => {
  return (
    <>
      <InnerDiv>
        <FooterLinks />
      </InnerDiv>
    </>
  )
}

export default InnerContainer
