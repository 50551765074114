import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { alvTokens } from '@dasa-health/alma-tokens'
import GlobalStyle from './GlobalStyle'

import Theme from '../utils/Theme'
import Footer from './footer'
import Breadcrumb from './breadcrumb'
import HeaderDasa from './header/HeaderDasa'
import { isHome } from '@cms/utils'

const OverflowWrapper = styled.div`
  overflow-x: hidden;
`

const Layout = ({ children, location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      wordpressWpGatsbyPage(slug: { eq: "home" }) {
        acf {
          placeholder_background  
          areas_title     
        }
      }
    }
  `)

  const homeData = data.wordpressWpGatsbyPage.acf
  const homeBackground = homeData.placeholder_background || "#02103F"

  return (
    <Theme>
        <OverflowWrapper 
          style={{
            backgroundColor: isHome(location) ? homeBackground : alvTokens.ColorNeutral10
          }}
        >
        <GlobalStyle />
        <HeaderDasa homeData={homeData} location={location} />
        <main 
          style={{
            backgroundColor: isHome(location) ? homeBackground : alvTokens.ColorNeutral10
          }}
        >
          <Breadcrumb pageContext={pageContext} location={location} />
          {children}
        </main>
        <Footer />
      </OverflowWrapper>
    </Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default Layout
