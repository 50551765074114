import React, { useEffect, useState } from 'react'
import * as S from './styled'
import Img from 'gatsby-image'
import GetAllFlagData from './getAllFlagData'

const VerticalFlagSubMenu = () => {
  const flags = GetAllFlagData()
  const [countFlagsActive, setCountFlagsActive] = useState(0)

  const filterActiveFlags = () => {
    return flags.filter(
      (flag) => flag.active === true
    )
  }

  useEffect(() => {
    const result = filterActiveFlags()
    setCountFlagsActive(result.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    countFlagsActive > 1 && (
      <S.DropdownVertical className='menuFlags'>
        {flags.map(flag => (
          flag.active === true && (
            <S.FlagItem href={flag.link} key={`flag_${flag.nome}`}>
              <S.FlagImg>
                <Img 
                  fluid={flag.icon.localFile.childImageSharp.fluid}
                  alt={flag.icon.alt_text}
                />
              </S.FlagImg>
  
              <S.FlagTitle>
                {flag.nome}
              </S.FlagTitle>
            </S.FlagItem>
          )
        ))}
      </S.DropdownVertical>
    )
  )
}

export default VerticalFlagSubMenu