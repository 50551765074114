import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SubMenuDasa from './SubMenuDasa'

const SubMenu = props => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeHeader {
        sidebars {
          widgets {
            value {
              items {
                title
                url
                wordpress_children {
                    title
                    url
                    wordpress_id
                }
              }
            }
          }
        }
      }
    }
  `)
  const menus = data.wordpressGeneoneThemeHeader.sidebars[0].widgets[2].value.items

  return <SubMenuDasa menus={menus} location={props} />
}

export default SubMenu
