import styled from 'styled-components'
import media from 'styled-media-query'

export const FooterStyled = styled.footer`
  clear: both;
  position: relative;
  padding: 5.6rem 5.6rem 4rem 5.6rem;
  background-color: var(--white);

  ${media.between('medium', 'large')`
    padding: 3.2rem 1.6rem 1.6rem;
  `}
  
  ${media.lessThan('medium')`
    padding: 3.2rem 1.6rem 1.6rem;
  `}
`
