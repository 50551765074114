import styled from 'styled-components'
import media from 'styled-media-query'

export const LogoContainer = styled.div`
  margin: 1.6rem 0 1rem 2.5rem;
  width: 62%;

  ${media.lessThan('large')`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20em;
    margin: 0 auto;

    a {
      display: flex;
    }

    .gatsby-image-wrapper,
    img {
      width: 5.2rem !important;
      height: 2.8rem !important;
    }
  `}
`
