import styled from 'styled-components'
import media from 'styled-media-query'

export const CircleButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size ? props.size : "40px"};
    height: ${props => props.size ? props.size : "40px"};
    background-color: #0C42F9;
    border-radius: 50%;

    svg {
        font-size: ${props => props.size ? '24px' : '32px' };
        color: var(--white);
    }

    &.hiddenMobile {
        ${media.lessThan('medium')`
            display: none;
        `}
    }

    &.hiddenDesktop {
        ${media.greaterThan('medium')`
            display: none;
        `}
    }
`;