import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

export const ItemsMenuNavDesktop = styled.nav`
  display: flex;
  justify-content: space-around;
  -moz-box-flex: 1;
  flex-grow: 1;
  height: 3rem;
  margin: 0px 2rem;
  padding: 1rem 1rem 0px;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ItemsMenuDasaNavDesktop = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0 0 9.4rem;
  min-width: 557px;
  padding: 4px 0 0 0;
  
  ${media.lessThan('large')`
  display: none;
  `}
  
  @media (min-width:1171px) and (max-width:1274px) {
    margin: 2rem 0 0 5.4rem;
  }
`

export const ItemsSubMenuDasaNavDesktop = styled.nav`
  display: flex;
  min-height: 4.3rem;
  margin: 2rem 3.2rem 0 9.4rem;
  padding: .4rem 0 0 0;

  a {
    color: #61636B;
    margin-right: 3.2rem;
    
    @media (min-width:1171px) and (max-width:1274px) {
      margin-right: 2.3rem;
    }
  }

  ${media.lessThan('large')`
    display: none;
  `}

  @media (min-width:1171px) and (max-width:1274px) {
    margin: 2rem 3.2rem 0 4.4rem
  }
`

export const MenuItem = css`
  font-size: 16px;
  margin: 0px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  cursor: pointer;
`

export const MenuItemDasa = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;
  margin-right: 3.2rem;
  cursor: pointer;
  
  &:last-child {
    margin-right: 0;
  }
  
  @media (min-width:1171px) and (max-width:1274px) {
    margin-right: 3.8rem;
  }
`

export const MenuItemNavDesktopCommom = styled.a`
  ${MenuItem}

  &:hover {
    border-bottom: 0.2rem solid;
  }
`

export const MenuItemDasaNavDesktopCommom = styled(Link)`
  ${MenuItemDasa}

  &:hover {
    border-bottom: 0.2rem solid;
  }

  &.nav-dasa {
    color: ${props => props.theme.colors.palette.black};
    font-weight: 600;
    margin: 0;

    &:hover {
      border-bottom: none;
    }
  }

  &.active {
    border-bottom: 2px solid #012169;
  }
`

export const MenuItemDasaNavDesktopCommomExternal = styled.a`
  ${MenuItemDasa}

  &:hover {
    border-bottom: 0.2rem solid;
  }

  &.nav-dasa {
    color: ${props => props.theme.colors.palette.black};
    font-weight: 600;
    margin: 0;

    &:hover {
      border-bottom: none;
    }
  }

  &.active {
    border-bottom: 2px solid #012169;
  }
`

export const MenuItemNavDesktop = styled.span`
  ${MenuItem}
  position: relative;
`

export const MenuItemDasaNavDesktop = styled.div`
  ${MenuItemDasa}
  position: relative;

  &:hover {
    .menuDropdown {
      display: flex;
    }
  }
`

export const MenuToggler = styled.span`
  display: block;
  height: 100%;
`

export const MenuDasaToggler = styled.span`
  display: flex;
  height: 4.05rem;

  &:hover {
    border-bottom: 0.2rem solid;
  }
`

export const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`

export const ItemLinkDropdown = styled.a`
  color: var(--pink);
  font-size: 16px;
  text-decoration: none;
  font-weight: 1000;
`

export const ItemLinkDropdownDasa = styled.p`
  color: ${props => props.theme.colors.brand.primary[10]};
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
`

export const SubitemsContainer = styled.div`
  display: flex;
  padding: 0;
`

export const SubMenuItemsDropdown = styled.div`
  padding: 2.4rem 0;
  margin: 0 auto;
`

export const SubMenuItemsDropdownDasa = styled(Link)`
  padding: .8rem 1.6rem;
  text-decoration: none;
  cursor: pointer;

  &:last-child {
    p {
      color: var(--pink);
      font-size: 14px;
      font-weight: 500;
      padding: 0;
    }
  
    &:hover {
      background-color: white;
    }
  }

  &:hover {
    background-color: #e8e8e8;
  }
`

export const DropdownArrow = styled.span`
  &::after {
    display: block;
    bottom: -0.5rem;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 14px solid var(--white);
    content: '';
    height: 0;
    left: calc(50% - 12px);
    position: absolute;
    width: 0;
    bottom: -0.5rem;

    ${media.lessThan('huge')`
      bottom: -2.5rem;
    `}

    ${media.lessThan('1310px')`
      bottom: -2.9rem;
    `}
  }
`

export const Dropdown = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 9.3rem;
  left: 0;
  width: 80vw;
  padding: 1rem 10%;
  margin: 0;
  background: var(--white);
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.175) 0px 6px 12px 0px;
`

export const DropdownVertical = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 3.2rem;
  left: -9rem;
  width: 16.6rem;
  margin: 0;
  background: var(--white);
  z-index: 100;
  box-shadow: 0px 2px 2px rgba(22, 22, 22, 0.04), 0px 4px 8px rgba(22, 22, 22, 0.08);
  border-radius: 4px;
  padding: .8rem 0 1.2rem 0;
`

export const VerticalSubmenuWrapper = styled.div`
  position: fixed;
  text-align: center;
  background: var(--white);
  border-radius: 5px;
  color: var(--pink);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-left: -1.2rem;
  margin-top: 2rem;
  min-width: 17rem;
  box-shadow: rgba(0, 0, 0, 0.175) 0px 6px 12px 0px;
`

export const VerticalSubmenuItem = styled(Link)`
  text-decoration: none;
  padding: 1rem 0;
  color: #7D7D7D;
  font-size: 16px;
  
  &:hover {
    color: var(--pink);
  }
`

export const ContactIconContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--white);
  letter-spacing: 0.1rem;
  margin-right: 2rem;

  ${media.lessThan('medium')`
    display: none;
    font-size: 14px;
  `}
`

export const ArrowIconContainer = styled.div`
  margin-left: 1rem;
  font-weight: bold;
`

export const SubMenuItemNavDasa = styled.div`
  display: flex;
  margin-right: 3.2rem;

  &:last-child {
    margin-right: 0;
  }
`