import React, {useContext} from 'react'
import LogoNav from '../../shared/LogoNavDasa/logoNav'
import ImgIllustration from './ImgIllustration'
import { Close } from '@dasa-health/alma-icons'
import * as S from './styled'
import ModalNavContext from '../../../utils/contexts/ModalNavContext'
import modalNav from '../modalNav'
import { getAlmaIconByName } from '../../shared/getAlmaIconByName'
import IconWrapper from '../../shared/IconWrapper'
import { getLinkByIcon } from '../../../utils/getLinkByIcon'

const MedicalChannelNav = ({data, slug}) => {
    const { 
        timerModalNav,
        setTimerModalNav
    } = useContext(ModalNavContext)

    const namData = data.medicalChannel_card_nam_ativo
                    ?
                        {
                            title: data.medicalChannel_nam_title,
                            icon1: getAlmaIconByName(data.medicalChannel_line1_icon),
                            txt1: data.medicalChannel_line1_txt,
                            link1: getLinkByIcon(data.medicalChannel_line1_icon, data.medicalChannel_line1_txt),
                            icon2: getAlmaIconByName(data.medicalChannel_line2_icon),
                            txt2: data.medicalChannel_line2_txt,
                            link2: getLinkByIcon(data.medicalChannel_line2_icon, data.medicalChannel_line2_txt),
                            icon3: getAlmaIconByName(data.medicalChannel_line3_icon),
                            txt3: data.medicalChannel_line3_txt,
                            link3: getLinkByIcon(data.medicalChannel_line3_icon, data.medicalChannel_line3_txt),
                            txt4: data.medicalChannel_line4_txt,
                            txt5: data.medicalChannel_line5_txt,
                        }
                    :
                        false

    function cancelTimerModalNav() {
      if(!timerModalNav) return 

      clearTimeout(timerModalNav)
      setTimerModalNav(false)
    }

    function closeModalNav(e, modal) {
        if(timerModalNav) return
      
        setTimerModalNav(
            setTimeout(function() {
                modalNav(e, modal)
            }, 1000)
        )
    }

    function onlyCloseModalNav(e, modal) {
        setTimerModalNav(true)
        modalNav(e, modal)
        cancelTimerModalNav()
    }

    return (
        <S.WhiteContainer 
            id="modalMedicalChannelNav"
            onMouseEnter={() =>{cancelTimerModalNav()}}
            onMouseLeave={(e) => {closeModalNav(e, slug)}}
        >
            <S.MedicalChannelContainer id="modal-medical-content">
                <S.ImgContainer>
                    {data.medicalChannel_modal_img
                        ?
                            <S.ImgModal 
                                alt={data.medicalChannel_modal_img.alt_text}
                                fluid={data.medicalChannel_modal_img.localFile.childImageSharp.fluid}
                            />
                        :
                            <ImgIllustration />
                    }
                </S.ImgContainer>  

                <S.CardsModalContainer>
                    <S.ForDoctors>
                        <S.LogoNavContent>
                            <LogoNav />
                        </S.LogoNavContent>

                        {data.medicalChannel_modal_title && (
                            <S.TitleForDoctors>
                                {data.medicalChannel_modal_title}
                            </S.TitleForDoctors>
                        )}

                        {data.medicalChannel_modal_description && (
                            <S.DescriptionForDoctors>
                                {data.medicalChannel_modal_description}
                            </S.DescriptionForDoctors>
                        )}

                        {data.medicalChannel_modal_btn_link && data.medicalChannel_modal_btn_title && (
                            <S.ButtonOpenNav 
                                href={data.medicalChannel_modal_btn_link}
                                target='blank'
                            >
                                {data.medicalChannel_modal_btn_title}
                                <S.AllowDesk>
                                    {data.medicalChannel_modal_btn_title_desk}
                                </S.AllowDesk>
                            </S.ButtonOpenNav>
                        )}
                    </S.ForDoctors>

                    {namData && (
                        <S.ForNam>
                            <S.TitleNam>
                                {namData.title}
                            </S.TitleNam>

                            <S.IconsContact>
                                <S.IconWithTxt>
                                    <S.IconContact>
                                        <IconWrapper Icon={namData.icon1} size="22" />
                                    </S.IconContact>

                                    <S.IconTxt href={namData.link1} target='_blank'>
                                        {namData.txt1}
                                    </S.IconTxt>
                                </S.IconWithTxt>

                                <S.IconWithTxt>
                                    <S.IconContact>
                                        <IconWrapper Icon={namData.icon2} size="22" />
                                    </S.IconContact>

                                    <S.IconTxt href={namData.link2} target='_blank'>
                                        {namData.txt2}
                                    </S.IconTxt>
                                </S.IconWithTxt>

                                <S.IconWithTxt>
                                    <S.IconContact>
                                        <IconWrapper Icon={namData.icon3} size="22" />
                                    </S.IconContact>

                                    <S.IconTxt href={namData.link3} target='_blank'>
                                        {namData.txt3}
                                    </S.IconTxt>
                                </S.IconWithTxt>
                            </S.IconsContact>

                            <S.TxtOpeningHours>
                                {namData.txt4}
                            </S.TxtOpeningHours>

                            <S.TxtOpeningHours>
                                {namData.txt5}
                            </S.TxtOpeningHours>
                        </S.ForNam>
                    )}

                </S.CardsModalContainer>

                <S.ButtonDiv onClick={(e) => onlyCloseModalNav(e, slug)}>
                    <S.ButtonClose>
                        <Close />
                    </S.ButtonClose>
                </S.ButtonDiv>
            </S.MedicalChannelContainer>
        </S.WhiteContainer>
    )
}

export default MedicalChannelNav
