import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../../shared/container'

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin: 3rem auto 0;
  z-index: 100;

  ${media.between('large', 'huge')`
    margin: 1.5rem auto 0;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    justify-content: space-between;
    margin: 1.5em 2em;
  `}
`

export const HeaderRelative = styled.header`
  display: flex;
  flex-direction: row;
  align-items: start;
  height: 90vh;
`

export const HeaderFixedContainer = styled.div`
  width: 100%;
  background-color: var(--pink);
  position: fixed;
  transition: all 0.5s ease;
  transform: translateY(0%);
  z-index: 3000;
  top: 0;
`

export const HeaderFixedDasaContainer = styled.div`
  width: 100%;
  background-color: var(--white);
  position: fixed;
  transition: all 0.5s ease;
  transform: translateY(0%);
  z-index: 3000;
  top: 0;
`

export const HeaderFixedContent = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem auto;

  ${media.lessThan('medium')`
    margin: 1.5em 2em;
  `}
`

export const HeaderFixedDasaContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 5fr;
  max-width: 1366px;
  margin: 0px auto;
  position: relative;

  ${media.lessThan('large')`
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.6rem 1.6rem 1.2rem 1.9rem;
  `}
`

export const SubMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const HeaderButtonsContainer = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    justify-content: space-between;
    width: 14rem;
    flex-direction: row-reverse;
  `}
`
