import React from 'react'
import * as S from './styled'

const IllustrationImage = (props) => {
    return (
        <S.IllustrationContainer>
          <S.Illustration
            fluid={props.image.localFile.childImageSharp.fluid}
            alt={props.image.alt_text}
          />
        </S.IllustrationContainer>
    )
}

export default IllustrationImage
