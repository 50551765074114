import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { GroupLinksDiv } from './styled'

import GroupLink from './GroupLink'
import GroupContactUs from './GroupContactUs'
import GroupNamContact from './GroupNamContact'

const GroupLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeFooter {
        sidebars {
          name
          widgets {
            type
            value {
              rendered
              items {
                title
                url
                object_id
              }
              name
            }
          }
        }
      }
    }
  `)

  const {
    wordpressGeneoneThemeFooter: { sidebars },
  } = data

  function findWidgetNam() {
    return sidebars.find(function(side) {
      return side.name === "NAM"
    })
  }

  return (
    <GroupLinksDiv>
      {sidebars.map(sidebar => {
        if(sidebar.widgets === null) {
          return null
        }

        if (sidebar.name === 'NAM') return null

        if (sidebar.name === 'Área Médica') {
          const items = [sidebar]
          const nam = findWidgetNam()

          if(nam) items.push(nam)

          return (
             <div>
              {items.map(item => {
                if(item.widgets === null) return null

                if(item.name === "NAM") {
                  return <GroupNamContact data={item} />
                } else {
                  return item.widgets.map(widget => {
                    return (
                      <GroupLink
                        key={`footer_${widget.value.name}_${Math.random()}`}
                        textHeader={widget.value.name || ''}
                        links={widget.value.items || []}
                      />
                    )
                  })
                }
              })}
            </div>
          )
        }

        if (sidebar.name === 'Contato') {
          if(sidebar.widgets.length === 1) return null
          return <GroupContactUs data={sidebar} />
        }

        return sidebar.widgets.map(widget => {
          return (
            <GroupLink
              key={`footerContact_${widget.value.name}_${Math.random()}`}
              textHeader={widget.value.name || ''}
              links={widget.value.items || []}
            />
          )
        })
      })}
    </GroupLinksDiv>
  )
}

export default GroupLinks
