import React from 'react'
import Img from 'gatsby-image'
import { ChevronRight } from '@dasa-health/alma-icons'
import * as S from './styled'

const AreaItem = (props) => {
    return (
        <S.AreaDiv>
            <S.AreaItem to={props.link}>
                <S.AreaIcon>
                    <Img 
                        fluid={props.icon.localFile.childImageSharp.fluid}
                        alt={props.icon.alt_text}
                    />
                </S.AreaIcon>

                <S.AreaItemTitle>
                    {props.title}
                </S.AreaItemTitle>

                <S.IconDiv>
                    <ChevronRight />
                </S.IconDiv>
            </S.AreaItem>
        </S.AreaDiv>
    )
}

export default AreaItem
