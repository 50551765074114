import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'
import { FooterTitle } from '../styled'

const GroupLink = props => {
  const Link = ({ link }) =>
    link.url.match(/^https?:\/\//g) ? (
      <S.LinkItemCommon href={link.url} target="_blank">
        {link.title}
      </S.LinkItemCommon>
    ) : (
      <S.LinkItem to={link.url}>{link.title}</S.LinkItem>
    )

  const { textHeader, links } = props
  return (
    links.length > 0 ? 
    <S.GroupLinkDiv>
      <FooterTitle>{textHeader}</FooterTitle>
      <S.LinksHolder>
        {links.map(link => (
          <S.LineList key={link.object_id}>
            <Link link={link} />
          </S.LineList>
        ))}
      </S.LinksHolder>
    </S.GroupLinkDiv>
    : 
      <></>
  )
}

GroupLink.propTypes = {
  textHeader: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
}

export default GroupLink
