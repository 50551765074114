import { useStaticQuery, graphql } from 'gatsby'

const GetFlagData = () => {
    const data = useStaticQuery(graphql`
        query {
            wordpressPage(slug: {eq: "site-settings"}) {
                acf {
                    nome_pais
                    link_pais
                    pais_br_ativo
                    imagem_pais {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    nome_pais_ar
                    link_pais_ar
                    pais_ar_ativo
                    imagem_pais_ar {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const flags = data.wordpressPage.acf
    let results = []

    switch (process.env.GATSBY_COUNTRY) {
        case "BR":
            results['sigla'] = "BR"
            results['hrefLang'] = "pt-br"
            results['nome'] = flags.nome_pais
            results['icon'] = flags.imagem_pais
            results['link'] = flags.link_pais
            results['active'] = flags.pais_br_ativo
            break;

        case "AR":
            results['sigla'] = "AR"
            results['hrefLang'] = "es-ar"
            results['nome'] = flags.nome_pais_ar
            results['icon'] = flags.imagem_pais_ar
            results['link'] = flags.link_pais_ar
            results['active'] = flags.pais_ar_ativo
            break;
    
        default:
            results['sigla'] = "BR"
            results['hrefLang'] = "pt-br"
            results['nome'] = flags.nome_pais
            results['icon'] = flags.imagem_pais
            results['link'] = flags.link_pais
            results['active'] = flags.pais_br_ativo
            break;
    }


  return results
}

export default GetFlagData
