import { Whatsapp, Phone, Mail, App } from '@dasa-health/alma-icons'

export const getAlmaIconByName = fontType => {
  const fonts = () => {
    switch (fontType) {
      case 'whatsapp':
        return Whatsapp;
      case 'phone':
        return Phone; 
      case 'email':
      case 'mail':
        return Mail;
      case 'menu':
        return App; 
      default:
        return null;
    }
  }

  return fonts()
}
