export const modalNav = (e, slug) => {
    e.preventDefault();
    
    const modalResults = document.getElementById("modalResultsNav")
    const modalMedical = document.getElementById("modalMedicalChannelNav")

    if(slug === "modal-results") {
        if(modalResults.style.height && modalResults.style.height !== '0px') {
            modalResults.style.height = 0

        } else {
            if(modalMedical) modalMedical.style.height = 0

            const contentHeight = document.getElementById("modal-results-content")
            modalResults.style.height = `${contentHeight.clientHeight}px`
        }
    }
    
    if(slug === "modal-medical-channel") {
        if(modalMedical.style.height && modalMedical.style.height !== '0px') {
            modalMedical.style.height = 0
            
        } else {
            if(modalResults) modalResults.style.height = 0

            const contentHeight = document.getElementById("modal-medical-content")
            modalMedical.style.height = `${contentHeight.clientHeight}px`
        }
    }

    if(typeof window !== `undefined`) {
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }
}

export default modalNav