import { useStaticQuery, graphql } from 'gatsby'

const GetAllFlagData = () => {
    const data = useStaticQuery(graphql`
        query {
            wordpressPage(slug: {eq: "site-settings"}) {
                acf {
                    nome_pais
                    link_pais
                    pais_br_ativo
                    imagem_pais {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    nome_pais_ar
                    link_pais_ar
                    pais_ar_ativo
                    imagem_pais_ar {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const flags = data.wordpressPage.acf

    const results = [
        {
            sigla: "BR",
            hrefLang: "pt-br",
            nome: flags.nome_pais,
            icon: flags.imagem_pais,
            link: flags.link_pais,
            active: flags.pais_br_ativo
        },
        {
            sigla: "AR",
            hrefLang: "es-ar",
            nome: flags.nome_pais_ar,
            icon: flags.imagem_pais_ar,
            link: flags.link_pais_ar,
            active: flags.pais_ar_ativo
        }
    ]

    return results
}

export default GetAllFlagData
