const showExams = country => {
    if((country === "BR" && process.env.GATSBY_ENABLE_EXAMS_BR === "true")) {
        return true
    }

    if((country === "AR" && process.env.GATSBY_ENABLE_EXAMS_AR === "true")) {
        return true
    }

    return false
}
  
module.exports = showExams
  