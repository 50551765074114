import styled from 'styled-components'
import media from 'styled-media-query'

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;

  ${media.lessThan('small')`
    padding-left: 0;
  `}

  ${media.between('medium', 'huge')`
    padding-left: 0;
  `}

  ${media.lessThan('medium')`
    margin: 0;
  `}

  .swiper-container {
    min-height: 340px;
    margin-left: 1.6rem;
    
    ${media.lessThan('medium')`
      min-height: 30.6rem;
    `}

    .swiper-slide {
        max-width: 253px;
    }
    
    .swiper-filler {
        width: calc(100vw - 300px);
    }

    .swiper-pagination {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        align-content: center;

        &.swiper-pagination-bullets {
            bottom: 1.6rem;

            ${media.lessThan('medium')`
              bottom: 0;
              height: .8rem;
            `}
        }

        &.swiper-pagination-bullets,
        &.swiper-pagination-clickable {
          .swiper-pagination-bullet {
            display: block;
            width: .8rem;
            height: .8rem;
            margin: 0 5px;
            border-radius: 10px;
            opacity: 1;
            background: #D12673;
            transition: all 0.3s;
          }

          .swiper-pagination-bullet-active {
            width: 2.4rem;
            height: .8rem;
            opacity: 1;
            background: #0C42F9;
            box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
          }
        }
    }
  }
`