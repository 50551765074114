import React, {Fragment} from 'react'
import AreasDesktop from './AreasDesktop';
import AreasMobile from './AreasMobile';

const AreasAtuacao = ({homeData}) => (
    <Fragment>
        <AreasDesktop homeData={homeData} />
        <AreasMobile homeData={homeData} />
    </Fragment>
)

export default AreasAtuacao
