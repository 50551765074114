import React from 'react'
import PropTypes from 'prop-types'

const IconWrapper = ({ Icon, size }) => <Icon size={size || '15'} />

IconWrapper.propTypes = {
  size: PropTypes.string,
}

export default IconWrapper
