import removeSpecialCharacters from './removeSpecialCharacters'

export const getLinkByIcon = (icon, value) => {
    const links = () => {
        switch (icon) {
            case 'whatsapp':
                return (`https://api.whatsapp.com/send?phone=55${removeSpecialCharacters(value)}`);
            case 'phone':
                return (`tel:+55${removeSpecialCharacters(value)}`);
            case 'email':
            case 'mail':
                return `mailto:${value}`;
            default:
                return '#';
        }
    }

    return links()
}
