import styled from 'styled-components'
import media from 'styled-media-query'
import { CircleButton } from '../../shared/CircleButton/styled';

export const AreasContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1094px;
    margin: 0 auto;

    ${media.lessThan('medium')`
        display: none;
    `}
`;

export const AreasMobileContainer = styled.div`
    display: none;

    ${media.lessThan('medium')`
        display: block;
        margin: 4rem auto;
    `}
`;

export const AreasTitleDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 3.6rem 0;

    ${media.lessThan('large')`
        justify-content: center;
    `}

    @media (min-width: 768px) and (max-width: 768px) {
        margin: 0 0 .6rem 0;
    }
`;

export const AreasTitle = styled.h2`
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 133%;
    color: var(--white);
    max-width: 792px;
    margin: 0;
    
    ${media.lessThan('large')`
        max-width: 792px;
    `}

    ${media.lessThan('medium')`
        max-width: calc(100vw - 3.2rem);
        padding: 0 1.6rem;
        margin: 0 0 1.6rem 0;
        font-size: 16px;
    `}
`;

export const AreasContent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    
    @media (max-width: 820px) {
        margin-left: 2.6rem;
        justify-content: flex-start;
    }

    @media (min-width: 821px) and (max-width: 1170px) {
        justify-content: center;
    }
`;

export const AreasList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.4rem;
    row-gap: 3.2rem;
    max-width: 981px;
    z-index: 10;

    ${media.lessThan('large')`
        gap: 1.4rem;
    `}

    @media (min-width: 768px) and (max-width: 1170px) {
        gap: .8rem;
    }
`;

export const ArrowRight = styled.div`
    display: flex;
    align-items: center;
    margin-left: 7.3rem;

    ${media.between('medium', 'large')`
        margin-left: 2.3rem;
    `}
        
    ${media.lessThan('medium')`
        margin-left: 5.3rem;
    `}
        
    @media (min-width: 768px) and (max-width: 1170px) {
        margin-left: 2.3rem;
    }
`;

export const CircleButtonBlue = styled(CircleButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    svg {
        font-size: 32px;
    }
`;