import styled from 'styled-components'
import media from 'styled-media-query'

export const GroupLinksDivMobile = styled.div`
  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const FooterTitleMobile = styled.div`
  display: flex;
  align-items: center;
  color: #D12673;
  margin-bottom: ${props => props.selected ? "1.6rem" : "0"};
  cursor: pointer;

  &.contact-footer {
    margin-bottom: 3.2rem;
  }
  
  svg {
    font-size: 1.5rem;
    margin-left: .8rem;
    transition: all .2s ease;
    transform: ${props => props.selected ? "rotate(90deg)" : ""};
  }
`
