import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from '@reach/router';

export const AreaDiv = styled.div`
    width: 311px;
    height: 80px;

    @media (max-width: 820px) {
        width: 237px;
        margin-bottom: 1.4rem;
    }

    @media (min-width: 821px) and (max-width: 1170px) {
        width: 237px;
        height: 70px;
        margin-bottom: 1.4rem;
    }
`;

export const AreaItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 1.6rem;
    height: 4.6rem;
    background: var(--white);
    border: 1px solid rgba(22, 22, 22, 0.08);
    border-radius: 8px;
    margin: 0 auto;
    text-decoration: none;
    cursor: pointer;

    svg {
        font-size: 16px;
    }
`;

export const AreaItemTitle = styled.h3`
    width: 175px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.theme.colors.palette.black};
    margin: 0 1.6rem 0 0;

    ${media.lessThan('large')`
        font-size: 14px;
        line-height: 115%;
        max-width: 100px;
    `}
`;

export const AreaIcon = styled.div`
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 1.6rem;
`;

export const IconDiv = styled.div`
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.palette.black};
`;