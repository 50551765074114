import React from 'react'
import CommonMenu from './commomMenu'
import DropdownMenu from './dropdownMenu'
import showExams from '../../../utils/showExams'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const MenuItem = props => {
  const showMenuExam = showExams(process.env.GATSBY_COUNTRY)
  const examsPageSlug = getSlugByCountry('exames')
  return (
    props.menu.url === `/${examsPageSlug}/` && !showMenuExam
    ?
      <></>
    :
      props.menu.wordpress_children && props.menu.wordpress_children.length > 0 ? (
        <DropdownMenu menu={props.menu} />
      ) : (
        <CommonMenu
          menu={props.menu}
          addClass={props.url.location.pathname === props.menu.url ? 'active' : ''}
        />
      )
  )
}

MenuItem.propTypes = {
  menu: DropdownMenu.propTypes.menu
}

export default MenuItem
