import React from 'react'
import GroupLinks from './GroupLinks'
import GroupLinksMobile from './GroupLinksMobile'
import FooterSocial from './FooterSocial'

const FooterLinks = () => {
  return (
    <>
      <GroupLinks />
      <GroupLinksMobile />
      <FooterSocial />
    </>
  )
}

export default FooterLinks
