import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styled'
import SocialListItem from './SocialListItem'

const SocialLinks = ( props ) => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeSettings {
        instagram_url
        linkedin_url
        youtube_url
        facebook_url
        footer_text,
      }
    }
  `)

  const {
    wordpressGeneoneThemeSettings: {
      instagram_url: instagramUrl,
      linkedin_url: linkedinUrl,
      youtube_url: youtubeUrl,
      facebook_url: facebookUrl,
      footer_text: footerText
    },
  } = data

  return (
    <>
      <S.SocialLinksHolder>
        <S.SocialLinksListHolder
          alignItem={props.alignItem ? props.alignItem : ''}
        >
          <SocialListItem size={props.iconsSize} link={linkedinUrl} type="linkedin" />
          <SocialListItem size={props.iconsSize} link={facebookUrl} type="facebook" />
          <SocialListItem size={props.iconsSize} link={youtubeUrl} type="youtube" />
          <SocialListItem size={props.iconsSize} link={instagramUrl} type="instagram" />
        </S.SocialLinksListHolder>
      </S.SocialLinksHolder>
      {footerText && (
        <S.FooterText>
          {footerText}
        </S.FooterText>
      )}
    </>
  )
}

SocialLinks.propTypes = {
  iconsSize: PropTypes.string,
}

SocialLinks.defaultProps = {
  iconsSize: '20',
}

export default SocialLinks
