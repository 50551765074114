import React from 'react'
import * as S from './styled'
import Img from 'gatsby-image'
import GetFlagData from '../../header/DropdownFlag/getFlagData'
import VerticalFlagSubMenu from './verticalFlagSubMenu'

const MenuItemFlag = () => {
  const flag = GetFlagData()

  return (
    <S.MenuItemDasaNavDesktop>
      <S.MenuDasaToggler>
        <S.FlagContainer>
          <S.FlagImg>
            <Img 
              fluid={flag.icon.localFile.childImageSharp.fluid}
              alt={flag.icon.alt_text}
            />
          </S.FlagImg>
          
          <VerticalFlagSubMenu />

        </S.FlagContainer>
        <S.InitialsFlag>
          {flag.sigla}
        </S.InitialsFlag>
      </S.MenuDasaToggler>
    </S.MenuItemDasaNavDesktop>
  )
}

export default MenuItemFlag
