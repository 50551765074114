import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { ArrowRight } from '@dasa-health/alma-icons'
import AreaItem from './AreaItem'
import * as S from './styled'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const AreasDesktop = ({homeData}) => {
    const areasPageSlug = getSlugByCountry('areasAtuacao')
    const data = useStaticQuery(graphql`
    query {
        allWordpressWpExpertiseAreaPage(sort: {fields: acf___ordem_desktop, order: ASC}) {
            edges {
                node {
                    acf {
                        link_atuacao
                        nome
                        icone {
                            alt_text
                            localFile {
                                childImageSharp {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        ordem_desktop
                    }
                }
            }
        }
    }`)

    const AreaItens = data.allWordpressWpExpertiseAreaPage.edges

    return (
        <S.AreasContainer>
            <S.AreasTitleDiv>
                <S.AreasTitle>
                    {homeData.areas_title ? homeData.areas_title : ''}
                </S.AreasTitle>
            </S.AreasTitleDiv>

            <S.AreasContent>
                <S.AreasList>
                    {AreaItens.map((item) => (
                        <AreaItem
                            title={item.node.acf.nome}
                            icon={item.node.acf.icone}
                            link={item.node.acf.link_atuacao}
                        />
                    ))}
                </S.AreasList>

                <S.ArrowRight>
                    <Link to={`/${areasPageSlug}/`} aria-label='Áreas de atuação'>
                        <S.CircleButtonBlue>
                            <ArrowRight />
                        </S.CircleButtonBlue>
                    </Link>
                </S.ArrowRight>
            </S.AreasContent>
        </S.AreasContainer>
    )
}

export default AreasDesktop
