import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'

export const GroupLinkDiv = styled.div`
  &:first-child {
    padding-left: 0;
  }
  
  ${media.between('medium', 'large')`
    min-width: 80px;
  `}

  ${media.lessThan('medium')`
    padding: 0;
    margin-bottom: 5rem;
  `}
`

export const TextHeader = styled.div`
  color: var(--pink);
  text-transform: uppercase;
  font-size: 14px;
  height: 3.3rem;
`

export const LineList = styled.li`
  font-size: 14px;
  display: table;
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LinkItem = styled(Link)`
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;

  ${media.lessThan('medium')`
    font-weight: 500;
  `}

  &:hover {
    text-decoration: underline;
  }
`

export const LinkItemCommon = styled.a`
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;
  cursor: pointer;

  ${media.lessThan('medium')`
    font-weight: 500;
  `}

  &:hover {
    text-decoration: underline;
  }
`

export const LinksHolder = styled.ul`
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;

  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr
  `}
`
