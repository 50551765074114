import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import MedicalChannelNav from './MedicalChannelNav'
import ResultsNav from './ResultsNav'

const BannerNav = () => {
  const data = useStaticQuery(graphql`
    query {
      modalResultsData: wordpressWpGatsbyPage(slug: {eq: "modal-results"}) {
        slug
        acf {
            results_modal_ativo
            results_modal_btn_close_title
            results_modal_btn_link
            results_modal_btn_title
            results_modal_description
            results_modal_description_desk
            results_modal_link
            results_modal_title
            results_modal_img {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
        }
      }
      modalMedicalChannelData: wordpressWpGatsbyPage(slug: {eq: "modal-medical-channel"}) {
        slug
        acf {
          medicalChannel_modal_ativo
          medicalChannel_modal_btn_link
          medicalChannel_modal_btn_title
          medicalChannel_modal_btn_title_desk
          medicalChannel_modal_description
          medicalChannel_modal_link
          medicalChannel_modal_title
          medicalChannel_nam_title
          medicalChannel_line1_icon
          medicalChannel_line1_txt
          medicalChannel_line2_icon
          medicalChannel_line2_txt
          medicalChannel_line3_icon
          medicalChannel_line3_txt
          medicalChannel_line4_txt
          medicalChannel_line5_txt
          medicalChannel_card_nam_ativo
          medicalChannel_modal_img {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const {modalResultsData, modalMedicalChannelData} = data

  const modalResults = modalResultsData.acf
  const slugResults = modalResultsData.slug
  
  const modalMedicalChannel = modalMedicalChannelData.acf
  const slugMedicalChannel = modalMedicalChannelData.slug

  return (
    <Fragment>
      {modalResults.results_modal_ativo && (
        <ResultsNav data={modalResults} slug={slugResults} />
      )}

      {modalMedicalChannel.medicalChannel_modal_ativo && (
        <MedicalChannelNav 
          data={modalMedicalChannel} 
          slug={slugMedicalChannel} 
        />
      )}
    </Fragment>
  )
}

export default BannerNav
