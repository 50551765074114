import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { CircleButton } from '../../shared/CircleButton/styled';
import { navTokens, alvTokens } from '@dasa-health/alma-tokens'

export const WhiteContainer = styled.div`
    width: 100%;
    background-color: var(--white);
    margin: 6.7rem 0 -6.7rem;
    height: 0;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    overflow: hidden;
`

export const MedicalChannelContainer = styled.div`
    max-width: 1366px;
    min-height: 34.2rem;
    margin: 0 auto;
    display: flex;
    position: relative;
    border-top: 1px solid #e8e8e8;

    ${media.lessThan('medium')`
        flex-direction: column-reverse;
        justify-content: center;
        padding: 3.2rem 0 2.375rem;
        min-height: auto;
    `}
`

export const ImgContainer = styled.div`
    display: contents;

    svg {
        width: 100%;
        max-width: 467.79px;
    }
`

export const ImgModal = styled(Img)`
    width: 56rem;
    height: 34.2rem;

    ${media.lessThan('medium')`
        width: 100%;
        height: auto;
        max-width: 320px;
        margin: 0 auto;
    `}
`

export const LogoNavContent = styled.div`
    width: 9.8rem;
    margin-bottom: 1.6rem;

    ${media.lessThan('medium')`
        width: 9.7rem;
    `}
`

export const CardsModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2.4rem;
    margin: 2.4rem 0 0 2.4rem;

    ${media.lessThan('medium')`
        flex-direction: column;
        align-items: center;
        margin: 0 0 1.6rem;
        gap: 1.6rem;
    `}
`

export const ForDoctors = styled.div`
    width: 27.3rem;
    height: 24.3rem;
    padding: 2.4rem;
    border-radius: 8px;
    background-color: #F5F5F5;
    max-width: 321px;
    margin: 0;

    ${media.lessThan('medium')`
        background-color: var(--white);
        width: 100%;
        height: auto;
        max-width: 33rem;
        padding: 0 1.6rem;
        align-items: center;
    `}
`

export const ForNam = styled(ForDoctors)`
`

export const TitleForDoctors = styled.p`
    margin: 0 0 .8rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: ${navTokens.ColorBrandPrimary60};

    &:last-child {
        margin: 0;
    }
    
    ${media.lessThan('medium')`
        font-size: 16px;
    `}
`

export const TitleNam = styled(TitleForDoctors)`
    color: var(--pink);

    ${media.lessThan('medium')`
        padding-top: 16px;
        border-top: 1px solid #1616163D;
    `}
`

export const IconsContact = styled.div`
    margin: 1.6rem 0;
`

export const IconWithTxt = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    margin: 0 0 .8rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: ${navTokens.ColorBrandPrimary60};

    &:last-child {
        margin: 0;
    }
    
    ${media.lessThan('medium')`
        font-size: 16px;
    `}
`

export const IconContact = styled.i`
    color: var(--pink);
    font-size: 2.4rem;
`

export const IconTxt = styled.a`
    font-size: 14px;
    color: var(--gray2);
    font-weight: 600;
    text-decoration: none;
`

export const TxtOpeningHours = styled.p`
    font-size: 1.4rem;
    color: var(--gray2);
    margin-bottom: .8rem;

    &:last-child {
        margin: 0;
    }
    
    ${media.lessThan('medium')`
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    `}
`

export const DescriptionForDoctors = styled.p`
    margin: 0 0 4.2rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 23.8px;
    color: ${alvTokens.ColorPaletteGray30};
    
    ${media.lessThan('medium')`
        font-size: 14px;
        line-height: 170%;
        margin: 0 0 1.6rem;
    `}
`

export const ButtonOpenNav = styled.a`
    display: flex;
    width: fit-content;
    text-decoration: none;
    background-color: ${navTokens.ColorBrandPrimary60};
    color: var(--white);
    padding: .8rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    cursor: pointer;
    
    ${media.lessThan('medium')` 
        font-size: 14px;
        line-height: 115%;
    `}
`

export const AllowDesk = styled.p`
    display: none;

    ${media.greaterThan('medium')`
        display: inline;
        margin-left: .4rem;
    `}
`

export const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    height: 2.4rem;
    position: absolute;
    top: 37px;
    right: 34px;
    cursor: pointer;

    ${media.lessThan('medium')`
        top: auto;
        bottom: 2.8rem;
        right: calc((100vw - 5.5rem) / 2);
    `}
`

export const ButtonClose = styled(CircleButton)`
    width: 2rem;
    height: 2rem;
    background-color: var(--pink);
    
    svg {
        font-size: 1.6rem;
    }

    ${media.lessThan('medium')`
        display: none;
    `}
`

export const ButtonText = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 133%;
    margin-left: .6rem;
    color: ${alvTokens.ColorPaletteGray30};
    
    ${media.lessThan('medium')`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.3rem;
        height: 3.2rem;
        margin: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 115%;
    `}
`