import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isHome } from '@cms/utils'

import * as S from './styled'
import LogoDasa from '../LogoDasa'
import MenuDesktopDasa from '../MenuDesktopDasa'
import SubMenu from '../MenuDesktopDasa/SubMenu'
import MenuMobile from '../MenuMobileDasa'
import MainTitle from '../../title'
import MenuItemFlag from '../DropdownFlag/menuItemFlag'
import AreasAtuacao from '../AreasAtuacao'
import BannerNav from '../../BannerNav'

const HeaderDasa = ({ homeData, location }) => {
  return (
    <Fragment>
      <S.HeaderFixedDasaContainer>
        <S.HeaderFixedDasaContent>
          <MenuMobile location={location} data-cy="header-menu-mobile-fixed" />
          <LogoDasa />
          <MenuDesktopDasa location={location} />
          <S.SubMenuContainer>
            <SubMenu location={location} />
            <MenuItemFlag />
          </S.SubMenuContainer>
        </S.HeaderFixedDasaContent>
      </S.HeaderFixedDasaContainer>
      <BannerNav />

      {isHome(location) && (
        <Fragment>
          <S.HeaderFixedDasaContainer>
            <S.HeaderFixedDasaContent>
              <MenuMobile location={location} data-cy="header-menu-mobile-fixed" />
              <LogoDasa />
              <MenuDesktopDasa location={location} />
              <S.SubMenuContainer>
                <SubMenu location={location} />
                <MenuItemFlag />
              </S.SubMenuContainer>
            </S.HeaderFixedDasaContent>
          </S.HeaderFixedDasaContainer>

          <BannerNav />
          <MainTitle />
        </Fragment>
      )}

      {isHome(location) && (
        <AreasAtuacao homeData={homeData} />
      )}
    </Fragment>
  )
}

HeaderDasa.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HeaderDasa
