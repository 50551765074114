import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const MenuMobileToggle = props => {
  const onInputChange = e => {
    props.onChange(e)
  }

  return (
    <S.MenuToggle {...props}>
      <input type="checkbox" checked={props.checked} onChange={onInputChange.bind(this)} aria-label="Menu mobile" />
      <span />
      <span />
      <span />
      {props.children}
    </S.MenuToggle>
  )
}

MenuMobileToggle.propTypes = {
  children: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default MenuMobileToggle
