import React from 'react'
import PropTypes from 'prop-types'
import SubMenuItem from './subMenuItem'
import LogoNavDasa from '../../shared/LogoNavDasa'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styled'

const SubMenuDasa = props => {
  const data = useStaticQuery(graphql`
    query {
      modalResultsData: wordpressWpGatsbyPage(slug: {eq: "modal-results"}) {
        slug
        acf {
            results_modal_ativo
            results_modal_link
        }
      }
      modalMedicalChannelData: wordpressWpGatsbyPage(slug: {eq: "modal-medical-channel"}) {
        slug
        acf {
          medicalChannel_modal_ativo
          medicalChannel_modal_link
        }
      }
    }
  `)

  const menuModalMedicalChannel = data?.modalMedicalChannelData
  const menuModalResults = data?.modalResultsData

  return (
    <S.ItemsSubMenuDasaNavDesktop>
      {props.menus.map((menu, index) => (
        <SubMenuItem key={index} menu={menu} url={props.location} />
      ))}

      {menuModalMedicalChannel.acf.medicalChannel_modal_ativo && (
        <SubMenuItem 
          key={menuModalMedicalChannel}
          url="#"
          menu={
            {
              url: '#', 
              title: menuModalMedicalChannel.acf.medicalChannel_modal_link,
              slug: menuModalMedicalChannel.slug
            }
          }
        />
      )}

      {(menuModalResults.acf.results_modal_ativo) && (
        <S.SubMenuItemNavDasa>
          <LogoNavDasa
            slug={menuModalResults.slug}
            menu={
              {
                url: '#', 
                title: menuModalResults.acf.results_modal_link,
                slug: menuModalResults.slug
              }
            }
          />
        </S.SubMenuItemNavDasa>
      )}
    </S.ItemsSubMenuDasaNavDesktop>
  )
}

SubMenuDasa.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      submenus: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  )
}

export default SubMenuDasa
