import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  margin: 0 auto;
  width: 141.4rem;
  max-width: calc(100% - 6rem);

  ${media.lessThan('medium')`
    max-width: calc(100% - 4rem);
  `}
`

export const ContainerDasa = styled.div`
  margin: 0 auto;
  max-width: 1092px;

  ${media.lessThan('medium')`
    padding: 0 1.6rem;
    max-width: calc(100vw - 3.2rem);
  `}

  ${media.between('medium', 'large')`
    padding: 0 1.6rem;
  `}
`

export const Column = styled.div``

export const EachInfoContainer = styled.div`
  border-bottom: ${props => (props.noBorder ? '0px' : '2px')} solid
    #e8e8e8;
  padding-bottom: 1.1rem;
  margin-bottom: ${props => (props.noBorder ? '0rem' : '2.5rem')};

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    ${media.lessThan('medium')`
      margin-bottom: 0;
    `}
  }
`

export const EachInfoSingleExamContainer = styled.div`
  border-bottom: 0;
  margin-bottom: ${props => (props.noBorder ? '0' : '5.6rem')};
  position: inherit;
  z-index: 2;

  ${media.lessThan('medium')`
      padding: 0 1.6rem;
      border: 0;
      margin-bottom: ${props => (props.noBorder ? '0' : '3.2rem')};
  `}
`
