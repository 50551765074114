import styled from 'styled-components'
import media from 'styled-media-query'

export const MenuToggle = styled.div`
  ${media.lessThan('large')`
    display: block;
    position: relative;
    z-index: 1000;
    -webkit-user-select: none;
    user-select: none;

    input {
      display: block;
      width: 4.7em;
      height: 1.6em;
      position: absolute;
      top: -5px;
      left: -6px;
      cursor: pointer;
      opacity: 0;
      z-index: 2000;
      -webkit-touch-callout: none;
    }

    span {
      display: block;
      width: 1.8em;
      height: .3em;
      margin-bottom: .4rem;
      position: relative;
      background-color: ${props => props.theme.colors.brand.primary[10]};
      transform-origin: 4px 0px;
      transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
        margin: 0;
      }
    }

    input:checked ~ span {
      opacity: 1000;
      transform: rotate(45deg) translate(-2px, -1px);
      background: ${props => props.theme.colors.brand.primary[10]};
    }

    input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    input:checked ~ span:nth-last-child(2) {
      opacity: 1;
      transform: rotate(-45deg) translate(0, -1px);
    }

    input:checked ~ ul {
      right: -214px;
      transform: scale(1, 1);
      opacity: 1;
    }
  `}
`
