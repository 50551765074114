import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { ChevronRight } from '@dasa-health/alma-icons'

import * as S from './styled'
import { FooterTitleMobile } from '../styled'

const GroupLinkMobile = props => {
  const [selected, setSelected] = useState(false)

  const menuTogglerRef = useRef(null)
  const dropdownRef = useRef(null)

  const handleSelectToggle = () => {
    if (!selected) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }

  const Link = ({ link }) =>
    link.url.match(/^https?:\/\//g) ? (
      <S.LinkItemCommon 
        href={link.url}
        target="_blank"
        className={props.class}
      >
        {link.title}
      </S.LinkItemCommon>
    ) : (
      link.title !== "NAM" ? (
        <S.LinkItem 
          to={link.url}
          className={props.class}
        >
          {link.title}
        </S.LinkItem>
      ) : (
        <S.LinkNam>
          {link.content}
        </S.LinkNam>
      )
    )

  const { textHeader, links } = props

  return (
      links.length > 0 && (
        <S.GroupLinkDiv>
          <FooterTitleMobile
            selected={selected}
            onClick={() => handleSelectToggle()}
            ref={menuTogglerRef}
          >
            <S.FooterTitleTxt>
              {textHeader}
            </S.FooterTitleTxt>
            <ChevronRight />
          </FooterTitleMobile>

          <S.LinksHolder 
            selected={selected}
            ref={dropdownRef}
          >
            {links.map(link => (
              <S.LineList key={link.object_id}>
                <Link link={link} />
              </S.LineList>
            ))}
          </S.LinksHolder>
        </S.GroupLinkDiv>
      )
  )
}

GroupLinkMobile.propTypes = {
  textHeader: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired
}

export default GroupLinkMobile
